import React, { Component} from 'react'
import { Link } from 'react-router-dom'
import {Utils} from "../../helper"
import {DpViewer} from "../../helper/file-viewer.jsx"

// import {ethers} from 'ethers'
import $ from 'jquery';
import api from "../../api"
import SignUp from './signUp'
import {ReactComponent as ItemIcon} from '../../images/my-item.svg';
import {ReactComponent as CartIcon} from '../../images/cart.svg';
import {ReactComponent as ActivityIcon} from '../../images/activity.svg';
import {ReactComponent as RewardIcon} from '../../images/reward.svg';
import {ReactComponent as ReferIcon} from '../../images/refer.svg';

// import { lang } from '../../lang'



class Profile extends Component {
  constructor(props) {
    super(props);
    this.blank = {
      canUser:false,
      address:'',
      username:"",
      verified:false,
      isLogged:false,
      isLoading:false,
      isLoaded:false,
      isUSer:false,
      name:'',
      balance:0,
    }
    this.state = this.blank
  }
  async componentDidMount (){
    // try {
    //   let _d = await window.tronLink.request({method: 'tron_requestAccounts'});
    //   console.log(_d);
    // } catch (e) {
    //
    // }

    let checker = setInterval(async()=>{
      if(window.tronWeb && window.tronWeb.defaultAddress.base58) {
        // console.log(window.tronWeb);

        clearInterval(checker)
        // if(window.tronWeb.defaultAddress.base58 === "TPEXXRBtKsQAkYE6x13wko1yWwE7AAGq45"){
        //   this.setState({address:"TPEXXRBtKsQAkYE6x13wko1yWwE7AAGq45",isLogged:true})
        //   Utils.setCreator("TPEXXRBtKsQAkYE6x13wko1yWwE7AAGq45");
        // }else {
        //   this.checkWallet()
        // }
        this.checkWallet()



      }
    },500)
  }
  // componentWillUnmount(){
  //   console.log('screen closing');
  //   let{isLogged,address} = this.state;
  //   if(isLogged){
  //     this.handleSignOut()
  //   }
  // }

  autoCheckingWallet = () =>{

    let checker = setInterval(async()=>{
      let {address}  = this.state
      if(!address){
        return clearInterval(checker);
      }
      let currentAddress = window.tronWeb.defaultAddress.base58;

      // console.log(this.state.address,currentAddress);

        if (currentAddress !== address) {
          clearInterval(checker);
          this.setState(this.blank,()=>{
            this.handleSignOut(false)
          });

          if(currentAddress){
            Utils.setToastAlert("Wallet changed, Please login again","info");
          }else if(address && !currentAddress) {
            Utils.setToastAlert("signed out of tron wallet","info");
          }
        }
    },2000)
  }

  checkWallet = async ()=>{
    try {
      let{isLoading} = this.state;
      let address = window.tronWeb.defaultAddress.base58;
      if(isLoading) return;
      // if(isLoading) return Utils.setToastAlert("Please wait while fetching your tron wallet");
      this.setState({isLoading:true});
      let res = await api.getUser({address,mode:"checkLogin"});
      // console.log(res);
      let{result,isLogged} = res.data;
      // if(!isLogged) return this.setState({isLoading:false})
      this.setState({isLoading:false})
      if(isLogged){
        let{name,username,following,liked,verified} = result;
        this.setState({address,name,username,verified,isLogged},()=>{
          this.autoCheckingWallet();
          this.handleGetBalance()
        });
        Utils.setCreator(address,following,liked,username,name,verified);
      }
    } catch (e) {
      // console.log(e);
      // console.error(e.response);
      let msg = e?.response?.data?.message??"";
      if(msg){
        Utils.setToastAlert(msg,"error");
      }
      this.setState({isLoading:false});
    }
  }
  handleWallet = async()=>{
    try {
      let{isLoading} = this.state;
      // console.log(window.tronWeb.defaultAddress);
      // await window.tronLink.request()
      if(!window.tronWeb) return $('#nologin-popup').show();

      if(!window?.tronWeb?.defaultAddress?.base58??false){
         window.tronLink.request({method: 'tron_requestAccounts'});
      }

      let address = window.tronWeb.defaultAddress.base58;
      if(!address)return Utils.setToastAlert("Please login your tron wallet",'info');
      if(isLoading) return console.log('is Loading');;
      this.setState({isLoading:true});
      let res = await api.getUser({address,mode:"checkLogin"});
      let{result,isLogged} = res.data;
      // if(!isLogged) return this.setState({isLoading:false})
      this.setState({isLoading:false})
      if(!!isLogged){
        let{name,username,following,liked,verified} = result;
        this.setState({address,name,username,verified,isLogged});
        Utils.setCreator(address,following,liked,username,name,verified);
        this.autoCheckingWallet();
        this.handleGetBalance()
      }else {
        this.loginWallet()
      }
    } catch (e) {

      // console.log(e.response);
      let canUser = e?.response?.data?.canUser??false;
      this.setState({canUser,isLoading:false});

      if(!canUser){
        let msg = e?.response?.data?.message??"Failed to login your account";
        Utils.setToastAlert(msg,"error");
      }

    }
  }

  loginWallet = async() =>{
    try {
      let time = await api.getServerTime();
      time = time.data.time;
      // console.log(time);
      let address = window.tronWeb.defaultAddress.base58;

      // let hexStrWithout0x = window.tronWeb.toHex({t:time}).replace(/^0x/, '');
      // let byteArray = window.tronWeb.utils.code.hexStr2byteArray(hexStrWithout0x)
      // let strHash = ethers.utils.keccak256(byteArray).replace(/^0x/, '');
      // console.log(strHash);
      //
      //
      // // let signedStr = await window.tronWeb.trx.sign(strHash);
      // // console.log(signedStr);
      // // let tail = signedStr.substring(128, 130);
      // // if(tail == '01')
      // // {
      // //     signedStr = signedStr.substring(0,128)+'1c';
      // // }
      // // else if(tail == '00')
      // // {
      // //     signedStr = signedStr.substring(0,128)+'1b';
      // // }
      // //
      // //
      // // // verify the signature
      // // let res = await window.tronWeb.trx.verifyMessage(strHash,signedStr,address)
      // // console.log(res);


      let hexMsg = window.tronWeb.toHex({t:time});
      const signedMsg = await window.tronWeb.trx.sign(hexMsg,null, false);

      let payload = ({address,hexMsg,signedMsg,})
      let res = await api.connectWallet(payload);
      let{name,username,following,liked,verified} = res.data.result;
      // console.log(res);
      this.setState({address,name,username,verified,isLogged:true,isLoading:false},()=>{
        this.autoCheckingWallet();
        this.handleGetBalance()
      });
      Utils.setCreator(address,following,liked,username,name,verified);
      Utils.setToastAlert("Wallet connected","success");

    } catch (e) {
      console.log(e);
      // console.log(e.response);
      this.setState({isLoading:false});
      if (e === 'Confirmation declined by user') {
        Utils.setToastAlert('Confirmation declined','warning')
      }else {
        // console.log(e);
        let msg = e?.response?.data?.message??`Failed to connect your wallet.`;
        Utils.setToastAlert(msg,'error')
      }
    }
  }

  handleGetBalance = async() =>{
    let {address}  = this.state
    try {
      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);
      this.setState({balance})
      // localStorage.setItem('activeUser', JSON.stringify({address,name,balance}));
    } catch (e) {
      this.setState({balance:0})
      // localStorage.setItem('activeUser', JSON.stringify({address,name,balance:0}));
    }
  }
  handleSignOut = async(alert = true) =>{
    try {
      if(this.state.isLoading && alert) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isLoading:true});

      await api.clearAuth()
      Utils.setCreator()
      this.setState(this.blank);
      if(alert){
        Utils.setToastAlert("Wallet disconnected","success");
      }
      this.setState({isLoading:false});
    } catch (e) {
      console.log(e.response);
      this.setState({isLoading:false});
      Utils.setToastAlert("Failed to disconnect wallet","error");
    }
  }
  handleSignUp = (address,name="",username="") =>{
    this.setState({
      address,name,username,
      canUser:false,
      isLogged:true,
      isLoading:false
    },()=>{
      this.autoCheckingWallet();
      this.handleGetBalance()
    });
  }
  handleHide = () =>{
    this.setState({canUser:false,isLoading:false});
  }

    render() {
      let {isLogged,address,name,balance,username,verified,canUser,isLoading} = this.state;
      // console.log(isLogged);
      let _name = name?Utils.shortName(name):Utils.viewOwner(username || address);
      let smallMenu = window.innerWidth < 1200;
      let currentUrl = window.location.href;
        return (
          <div className="header__actions">
            <div className="header__action header__action--search">
              <button className="header__action-btn" type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg></button>
            </div>
            {!isLogged && <div className="header__action header__action--signin">
              <button
                className="header__action-btn header__action-btn--signin"
                onClick={this.handleWallet}
                >
                {!isLoading && <span>Connect Wallet</span>}
                {!isLoading && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20,12a1,1,0,0,0-1-1H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H19A1,1,0,0,0,20,12ZM17,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V16a1,1,0,0,0-2,0v3a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V8a1,1,0,0,0,2,0V5A3,3,0,0,0,17,2Z"/></svg>}
                  {!!isLoading &&  <div className="ball__pulse">
                    <div></div>
                    {!smallMenu &&<div></div>}
                    {!smallMenu &&<div></div>}
                  </div>}
              </button>
            </div>}

            {isLogged && <div className="header__action header__action--profile">
              <a href={currentUrl} className={`header__profile-btn ${verified?"header__profile-btn--verified":""}`} role="button" id="dropdownMenuProfile" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <DpViewer address={address} key={address} />
                <div><p>{_name}</p>
                <span>{Utils.preety.floor(balance/1e6,1)} TRX</span></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path></svg>
              </a>

              <ul className="dropdown-menu header__profile-menu" aria-labelledby="dropdownMenuProfile">
                <li><Link to={`/author/${address}/profile?tab=onsale`}><CartIcon/><span>On Sale</span></Link></li>
                <li><Link to={`/author/${address}/profile?tab=collection`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg> <span>Collection</span></Link></li>
                <li><Link to={`/author/${address}/profile?tab=created`}><ItemIcon/><span>Created</span></Link></li>
                <li><Link to={`/author/${address}/profile?tab=activity`}><ActivityIcon/><span>Activities</span></Link></li>
                <li><Link to={`/author/${address}/rewards`}><RewardIcon/><span>Rewards</span></Link></li>
                <li><Link to={`/author/${address}/referral`}><ReferIcon/><span>Referral</span></Link></li>
                <li>
                  <button
                  onClick={this.handleSignOut}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>
                    <span>Sign out</span>
                  </button>
                </li>
              </ul>
            </div>}
            {canUser && <SignUp
              address={address}
              hide={this.handleHide}
              handleSignUp={this.handleSignUp}
              />}
          </div>
        )
    }
}








export default Profile
