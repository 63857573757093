import axios from 'axios'
// import https from 'https'


// let url = "https://192.168.29.238:5000"
let url = "https://server.kraftly.io"


// let cdn = "https://192.168.35.209:6001"
let cdn = "https://cdn.kraftly.io"

const apiFile = axios.create({
    baseURL: `${cdn}/api/v1`,
    withCredentials: true,
    credentials: 'include',
})

const api = axios.create({
    baseURL: `${url}/api/v1`,
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
})
const impApi = axios.create({
    baseURL: `${url}/api/auth/v1`,
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
})


const fileApi = axios.create({
    timeout: 4 * 60 * 1000,
    withCredentials: true,
    credentials: 'include',
    responseType: 'blob'
})






export const uploadFolder = (payload,config) => apiFile.post(`/uploadFolder`,payload,config)


// export const getNftFile = tokenId => apiFile.get(`/images/${tokenId}`)
export const getServerTime = () => api.get('/time')

export const getNft = payload => api.post(`/getNft`,payload)
export const newNft = (payload,config) => api.post(`/newNft`,payload,config)
export const getBatchNft = payload => api.post(`/getBatchNft`,payload)

export const getEvents = payload => api.post(`/getEvents`,payload)

export const getSimilarNft = payload => api.post(`/getSimilarNft`,payload)
export const getMarket = payload => api.post(`/getMarket`,payload)
export const get3pMarket = payload => api.post(`/get3pMarket`,payload)
export const get3pBatchNft = payload => api.post(`/get3pBatchNft`,payload)
export const get3pState = payload => api.post(`/get3pState`,payload)

export const getDiscover = payload => api.post(`/getDiscover`,payload)
export const getNeoMarket = payload => api.post(`/getNeoMarket`,payload)

export const getNeoState = payload => api.post(`/getNeoState`,payload)


export const getUserCreatedNft = payload => api.post(`/getUserCreatedNft`,payload)
export const getUserActivity = payload => api.post(`/getUserActivity`,payload)
export const getRewards = payload => api.post(`/getRewards`,payload)

export const getTopAuthors = payload => api.post(`/getTopAuthors`,payload)

export const getUser = payload => api.post(`/getUser`,payload)
export const connectWallet = payload => api.post(`/connectWallet`,payload)
export const insertUser = payload => api.post(`/insertUser`,payload)

export const getBlindCollection = payload => api.post(`/getBlindCollection`,payload)
export const getBlindCollections = payload => api.post(`/getBlindCollections`,payload)
export const getBlindStatus = payload => api.post(`/getBlindStatus`,payload)
export const updateBlindStatus = payload => api.post(`/updateBlindStatus`,payload)

export const getIkGallery = payload => api.post(`/getIkGallery`,payload)
export const getIkCollectionDetails = payload => api.post(`/getIkCollectionDetails`,payload)
export const getAuthorIkCollection = payload => api.post(`/getAuthorIkCollection`,payload)
export const getIkCollections = payload => api.post(`/getIkCollections`,payload)
export const updateOldBlindCol = payload => api.post(`/updateOldBlindCol`,payload)


export const getAuthors = payload => api.post(`/getAuthors`,payload)


export const getNftContract = payload => api.post(`/getNftContract`,payload)
export const getNftContracts = payload => api.post(`/getNftContracts`,payload)


export const getCsfrTokenFromServer = () =>impApi.get(`/csrf-token`)
const getCsrfToken = async () => {
  let data = await getCsfrTokenFromServer();
  impApi.defaults.headers.common["X-CSRF-Token"] = data.data.csrfToken;
 }

 export const clearAuth = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/clearAuth`,payload)
 }
 export const updateUser = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/updateUser`,payload)
 }
 export const uploadAuthorDp = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/uploadAuthorDp`,payload)
 }
 export const updateSocialLinks = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/updateSocialLinks`,payload)
 }
 export const uploadAuthorBanner = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/uploadAuthorBanner`,payload)
 }
 export const likeNft = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/likeNft`,payload)
 }
 export const followAuthor = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/followAuthor`,payload)
 }

 export const updateAdminAuthor = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/updateAdminAuthor`,payload)
 }

 export const updateNftAdmin = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/updateNftAdmin`,payload)
 }

 export const createNewBlindNft = async(payload,config) => {
   await getCsrfToken();
   return impApi.post(`/createNewBlindNft`,payload,config)
 }
 export const uploadCollectionMeta = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/uploadCollectionMeta`,payload)
 }


 export const createNewIkNft = async(payload,config) => {
   await getCsrfToken();
   return impApi.post(`/createNewIkNft`,payload,config)
 }
 export const editIkCollectionMeta = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/editIkCollectionMeta`,payload)
 }
 export const uploadCollectionFile = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/uploadCollectionFile`,payload)
 }
 export const publicIkCollection = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/publicIkCollection`,payload)
 }


 export const editTRC721Meta = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/editTRC721Meta`,payload)
 }
 export const uploadTRC721File = async(payload) => {
   await getCsrfToken();
   return impApi.post(`/uploadTRC721File`,payload)
 }

const apis = {
  fileApi,


  uploadFolder,//upload on cdn server

  getServerTime,

  getNft,
  newNft,
  getBatchNft,

  getEvents,

  getSimilarNft,
  getMarket,
  get3pMarket,
  get3pBatchNft,
  get3pState,

  getDiscover,
  getNeoMarket,
  getNeoState,

  getUserCreatedNft,
  getUserActivity,
  getRewards,

  getTopAuthors,

  getNftContract,
  getNftContracts,

  updateUser,
  getUser,
  insertUser,
  connectWallet,
  getAuthors,

  uploadAuthorDp,
  uploadAuthorBanner,
  updateSocialLinks,


//auth apis lists
  clearAuth,


  likeNft,
  followAuthor,


  updateAdminAuthor,
  updateNftAdmin,


  createNewBlindNft,
  uploadCollectionMeta,
  getBlindCollection,
  getBlindCollections,
  getBlindStatus,
  updateBlindStatus,

  createNewIkNft,
  getIkGallery,
  getIkCollectionDetails,
  getAuthorIkCollection,
  getIkCollections,
  updateOldBlindCol,
  editIkCollectionMeta,
  uploadCollectionFile,
  publicIkCollection,

  editTRC721Meta,
  uploadTRC721File,

}


export default apis
