import React, { Component,Fragment } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// import { Link } from 'react-router-dom'

import api from "../../api"
import tronWeb from "../../tronweb"
import Data from "./data.js"
import {Utils} from "../../helper"
import * as Tasks from './helper'
import MarketBody from '../nft-details/market-body'
// import $ from "jquery";


const SubmitBtn = styled.button.attrs(props=>({
  className: `market__btn ${props.fullView?"market__btn__full":""}`,
  type:"button"
}))`
  background-color:${props =>props.red?"red":props.green?"green":"#6164ff"};
  &:hover {
    background-color: #222227;
  }
`

class NftDetails extends Component {
  constructor(props) {
      super(props)
      this.blank = {
        isPopUp:false,
        inMarket:false,
        isHistory:false,

        address:'',
        tab:"history",
        tokenId:this.props.tokenId || "",
        nftAddress:this.props.nftAddress || "",

        history:[],

        owner:'',
        bidder:'',
        highestBidTime:0,
        currencyId:'',
        bid:0,
        price:0,
        time:0,

        collection:{},
        author:{},
        nft:{},
        nftDoc:{},
      }
      this.state = this.blank;

  }
  componentDidMount = async () =>{
    // this.fetchMarketDetails();
    this.fetchNft()
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})

    })
  }
  componentDidUpdate(prevProps) {
    let tokenId = this.props.tokenId;
   if(tokenId && prevProps.tokenId !== tokenId){
     this.blank.tokenId = tokenId;
     this.setState(this.blank,this.fetchMarketDetails)
   }
 }
 componentWillUnmount(){
   document.title = "Kraftly";
 }

 fetchNft = async() =>{
   const{tokenId,nftAddress} = this.state
   try {
     let payload ={tokenIds:[tokenId],nftAddress,mode:"marketplace"}
     let NftData = await api.get3pBatchNft(payload);
     let{nft,collection,author,nftDoc} = NftData.data;
     // console.log(NftData.data);
     this.setState({nft,collection,author,nftDoc},()=>{
       this.fetchMarketDetails()
       this.fetchHistory()
     });

     document.title = nft.name || "Market place";
   } catch (e) {
     let msg = e?.response?.data?.message??"NFT details not found";
     Utils.setToastAlert(msg,"error");
     // console.error(e);
   }
 }

 fetchMarketDetails = async() =>{


   try {
     const{tokenId,nftAddress,nftDoc} = this.state
     // console.log(nftDoc);
     if(!nftDoc.market) {
       this.fetchNftOwner()
       return Utils.setToastAlert("Market place not enabled yet");
     }

     let instanceMarket = await tronWeb.contract(Data.abi,nftDoc.market);
     // console.log(resudfdt);
     let result = await instanceMarket.auctionMeta(tokenId).call({_isConstant:true});


     if(result[0] !== "410000000000000000000000000000000000000000"){
       let data = {
         owner:tronWeb.address.fromHex(result[0]),
         price:tronWeb.toDecimal(result[1]),
         time:tronWeb.toDecimal(result[2]),
         bidder:tronWeb.address.fromHex(result[3]),
         bid:tronWeb.toDecimal(result[4]),
         highestBidTime:tronWeb.address.fromHex(result[5]),
         currencyId:tronWeb.address.fromHex(result[6]),
         inMarket:true,
       }
       // console.log(data);
       this.setState(data)
     }else {
       let instanceNFT = await tronWeb.contract().at(nftAddress)
       let owner = await instanceNFT.ownerOf(tokenId).call();

       owner = owner._owner?owner._owner:owner;
       this.setState({
         owner:tronWeb.address.fromHex(owner),
         bidder:tronWeb.address.fromHex(result[3]),
         bid:tronWeb.toDecimal(result[4]),
         highestBidTime:tronWeb.address.fromHex(result[5]),
         currencyId:tronWeb.address.fromHex(result[6]),
       })
     }
   } catch (e) {
     this.setState({owner:Utils.Zero_address})
     // console.log('error occured');
     // console.log(e);
   }
 }

 fetchNftOwner = async() =>{
   try {
     const{tokenId,nftAddress} = this.state

     let instanceNFT = await tronWeb.contract().at(nftAddress)
     let owner = await instanceNFT.ownerOf(tokenId).call();
     owner = owner._owner?owner._owner:owner;
     this.setState({owner:tronWeb.address.fromHex(owner)})
   } catch (e) {
     this.setState({owner:Utils.Zero_address})
   }
 }

  fetchHistory = async() =>{
    let{tokenId,nftAddress} = this.state;
    try {
      let history  = await api.getEvents({tokenId,nftAddress});
      history = history.data.result
      history = history.sort((a, b) => new Date(b.createdAt).getTime() -  new Date(a.createdAt).getTime());

      // let {name,owner,bid,bidder,price,time,account} = history[0]
      this.setState({history})

      // let data = {history}

      // data.owner = !history || !history.length?creator:name === "AuctionOrder" || name === "AuctionBid"?owner:name === "SoldAuction" || name === "NFTminted"?bidder:name === "CancelAuction"?account:"";
      // if(name === "AuctionBid" || name === "AuctionOrder"){
      //   data.price = price || 0;
      //   data.time = time || 0;
      //   data.bid = bid || 0;
      //   data.bidder = bidder || "";
      //   data.inMarket = true;
      // }
      //update data according to history
      // this.setState(data)
    } catch (e) {
      // console.log(e);
      // console.log(e.response);
    }finally{
      this.setState({isHistory:true})
    }
  }

  handleReloadDetails = async() => {
    try {
      const{tokenId,nftAddress,nftDoc} = this.state;
      if(!nftDoc.market) return Utils.setToastAlert("Market place not enabled yet");


      let instanceMarket = await tronWeb.contract(Data.abi,nftDoc.market);
      let result = await instanceMarket.auctionMeta(tokenId).call({_isConstant:true});

      if(result[0] !== "410000000000000000000000000000000000000000"){

        this.setState({
          owner:tronWeb.address.fromHex(result[0]),
          price:tronWeb.toDecimal(result[1]),
          time:tronWeb.toDecimal(result[2]),
          bidder:tronWeb.address.fromHex(result[3]),
          bid:tronWeb.toDecimal(result[4]),
          highestBidTime:tronWeb.address.fromHex(result[5]),
          currencyId:tronWeb.address.fromHex(result[6]),
          inMarket:true,
          isPopUp:false
        })
      }else {
        let instanceNFT = await tronWeb.contract().at(nftAddress)
        let owner = await instanceNFT.ownerOf(tokenId).call();
        owner = owner._owner?owner._owner:owner;

        this.setState({
          owner:tronWeb.address.fromHex(owner),
          price:0,
          time:0,
          bidder:tronWeb.address.fromHex(result[3]),
          bid:tronWeb.toDecimal(result[4]),
          highestBidTime:tronWeb.address.fromHex(result[5]),
          currencyId:tronWeb.address.fromHex(result[6]),
          inMarket:false,
          isPopUp:false
        })
      }
    } catch (e) {
      console.log(e);
      Utils.setToastAlert("Failed to reload NFT details");
    }
  }

  redirectIkCol = () =>{
    let{nft,collection} = this.state;
    window.location.href =`/mintable/${collection.collectionId}/gallery?s=${nft.tokenId}`
  }
  render() {
    let {tokenId,nftAddress,address,owner,time,bid,price,bidder,inMarket,isPopUp,currencyId,highestBidTime,collection,nftDoc} = this.state;
    // address = owner;
    // time = 0;
    let isOwner = address && address === owner?true:false;
    let timeNow = Math.floor(Date.now()/1000);
    let canOwnerCancel = !time || !bid || (time < timeNow && bid < price)?true:false;
    let canOwnerRelease =  !!bid;
    let canUserRelease = time && time <= timeNow && address === bidder && bid >= price;

    // bidder = address;
    let ikMintable = owner === Utils.Zero_address && !!collection.collectionId;
    let market = nftDoc.market || "";

    return (
      <MarketBody
        data={this.state}
        >

          {!!address && !inMarket &&( <div className="market__btns">
            {!!isOwner && <Tasks.InsertAuction
              data={{tokenId,owner,nftAddress,market,isPopUp,fullView:!bid}}
              close={()=>this.setState({isPopUp:false})}
              open={()=>this.setState({isPopUp:true})}
              reFetch = {this.handleReloadDetails}
              />}
            {!!bid && !!isOwner && <Tasks.AcceptOffer
              data={{tokenId,owner,bid,currencyId,nftAddress,market,inMarket,time,fullView:false}}
              reFetch={this.handleReloadDetails}
              close={()=>this.setState({isPopUp:false})}
              open={()=>this.setState({isPopUp:true})}
              />}

            {!isOwner && address !== bidder && !ikMintable && <Tasks.MakeAnOffer
              data={{tokenId,bidder,bid,currencyId,nftAddress,market,isPopUp,fullView:true}}
              close={()=>this.setState({isPopUp:false})}
              open={()=>this.setState({isPopUp:true})}
              reFetch={this.handleReloadDetails}
              key={bid+currencyId}
              />}

              {!isOwner && address === bidder && <Tasks.WithdrawBid
                data={{tokenId,bidder,bid,currencyId,nftAddress,market,highestBidTime,fullView:!ikMintable}}
                reFetch={this.handleReloadDetails}
                />}

              {!isOwner && ikMintable && <SubmitBtn fullView={address !== bidder} onClick={this.redirectIkCol}>Mint Now</SubmitBtn>}

          </div>)}

          {!!address && inMarket && (<Fragment>

            {isOwner && <div className="market__btns">
              {canOwnerCancel && <Tasks.CancelAuction data={{tokenId,owner,nftAddress,market,fullView:!canOwnerRelease}} reFetch={this.handleReloadDetails}/>}
              {!!canOwnerRelease && <Tasks.AcceptOffer
                data={{tokenId,owner,bid,currencyId,nftAddress,market,inMarket,time,fullView:!canOwnerCancel}}
                reFetch={this.handleReloadDetails}
                close={()=>this.setState({isPopUp:false})}
                open={()=>this.setState({isPopUp:true})}
                />}
            </div>}

            {!isOwner && (<div className="market__btns">
            {!canUserRelease && !time && !!price && <Tasks.BuyFixedNft data={{tokenId,bidder,price,nftAddress,market,currencyId}} reFetch={this.handleReloadDetails}/>}
            {!!canUserRelease && <Tasks.AcceptOffer
              data={{tokenId,owner,bid,currencyId,nftAddress,market,inMarket,time,fullView:true}}
              reFetch={this.handleReloadDetails}
              close={()=>this.setState({isPopUp:false})}
              open={()=>this.setState({isPopUp:true})}
              />}

            {!canUserRelease && address !== bidder &&<Tasks.ModelBid
              data={{tokenId,bidder,bid,price,time,nftAddress,market,currencyId,isPopUp,fullView:!(!time && !!price)}}
              close={()=>this.setState({isPopUp:false})}
              open={()=>this.setState({isPopUp:true})}
              reFetch={this.handleReloadDetails}
              />}
              {!canUserRelease && address === bidder && !time  && <Tasks.WithdrawBid
                data={{tokenId,bidder,bid,currencyId,nftAddress,market,highestBidTime,fullView:false}}
                reFetch={this.handleReloadDetails}
                />}
            </div>)}
          </Fragment>)}
      </MarketBody>

    )
  }
}

function NftDetailsWithProps(props){
    const params = useParams();
    let {tokenId,nftAddress} = params || {};
    let account = tronWeb.isAddress(nftAddress)?nftAddress:"";
    return (
      <NftDetails
          {...props}
          tokenId={tokenId}
          nftAddress={account}
      />
    );
}


export default NftDetailsWithProps
