import React, { Fragment,useState } from 'react'
import {Utils} from "../../helper";

import './styles.scss';

const CollectionSummery = (props) =>{

  let {count,floorPrice,volume,transaction} = props?.data??{};

  return(
    <div className="subProject__summery">
      <div className="subProject__summery__body">
        <div className="subProject__summery__each">
          <p>Items</p>
          <span>{Utils.preety.ceil(count,0)}</span>
        </div>
        <div className="subProject__summery__each">
          <p>Floor Price</p>
          <span>{Utils.preety.ceil(floorPrice/1e6,0)} TRX</span>
        </div>
        <div className="subProject__summery__each">
          <p>Volume traded</p>
          <span>{Utils.preety.ceil(volume/1e6,0)} TRX</span>
        </div>
        <div className="subProject__summery__each">
          <p>Transactions</p>
          <span>{Utils.preety.ceil(transaction,0)}</span>
        </div>
      </div>
    </div>
  )
}
export default CollectionSummery;
