import React, {Fragment,useState,useEffect,useRef} from 'react'
// import {Utils} from "../../helper"
import './video-player.scss'

import {ReactComponent as Play} from '../../images/play.svg';
import {ReactComponent as Pause} from '../../images/pause.svg';
import {ReactComponent as Speaker} from '../../images/speaker.svg';
import {ReactComponent as Muted} from '../../images/muted.svg';

const VideoPlayer = React.memo(({src=""}) => {

  const videoRef = useRef(null);
  const progressRef = useRef(0);

  const [isMuted, setMuted] = useState(true);
  const [isPlaying, setPlaying] = useState(false);

  const handleOnTimeUpdate = () => {
    let video = videoRef.current;
    if(!!video) {
      progressRef.current = (video.currentTime / video.duration) * 100;
      if (progressRef.current === 100) {
        video.play()
        .then(()=>setPlaying(true))
        .catch(()=>setPlaying(false));
      }
    }
  };

  useEffect(() => {
    if(!videoRef.current) return;
    videoRef.current.muted = true;
    togglePlay()
  },[]);

  const toggleMute = (e) => {
    if(!videoRef.current) return;
    e?.preventDefault();
    videoRef.current.muted = !isMuted;
    setMuted(!isMuted);
  }

  const togglePlay = (e) => {
    if(!videoRef.current) return;
    e?.preventDefault();
    if(!isPlaying){
      videoRef.current.play()
      .then(()=>setPlaying(true))
      .catch(()=>setPlaying(false));
    }else {
      videoRef.current.pause();
      setPlaying(false)
    }
  }

  return (
    <Fragment>
      {!!src && <video
        src={src}
        ref={videoRef}
        onTimeUpdate={handleOnTimeUpdate}
        playsInline
      >
        Your browser does not support the video tag.
      </video>}

    {!!src && <div className="video-controls">
        <div className="mute_btn" onClick={toggleMute}>
          {!!isMuted && <Muted />}
          {!isMuted && <Speaker />}
        </div>
        <div className="play_btn" onClick={togglePlay}>
          {!isPlaying && <Play />}
          {!!isPlaying && <Pause />}
        </div>
      </div>}
    </Fragment>
  );
});


export default VideoPlayer;
