import React, { Component,Fragment } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// import { Link } from 'react-router-dom'
import './style.scss';

// import $ from "jquery";
import api from "../../api"
import tronWeb from "../../tronweb"

import {Utils} from "../../helper"
import MarketBody from './market-body'
import * as Tasks from './helper'

const SubmitBtn = styled.button.attrs(props=>({
  className: `market__btn ${props.fullView?"market__btn__full":""}`,
  type:"button"
}))`
  background-color:${props =>props.red?"red":props.green?"green":"#6164ff"};
  &:hover {
    background-color: #222227;
  }
`

// const SaleAnchor = styled(Link).attrs({
//   className:"asset__btn asset__btn--full asset__btn--clr"
// })``



class NftDetails extends Component {
  constructor(props) {
      super(props)
      this.blank = {
        isPopUp:false,
        inMarket:false,
        history:[],
        address:'',
        tab:"history",
        tokenId:this.props.tokenId || "",

        owner:'',
        bidder:'',

        bid:0,
        price:0,
        time:0,

        isHistory:false,


        nft:{},
        collection:{},
        author:{},
        nftDoc:{
          name:"Kraft NFT",
          symbol:"KBFT",
          verified:true,
        }

      }
      this.state = this.blank;

  }
  componentDidMount = async () =>{
    if(this.state.tokenId){
      this.fetchNft()
    }
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
  }
  componentDidUpdate(prevProps) {
    let tokenId = this.props.tokenId;
   if(tokenId && prevProps.tokenId !== tokenId){
     this.blank.tokenId = tokenId;
     this.setState(this.blank,this.fetchNft)
   }
 }
 componentWillUnmount(){
   document.title = "Kraftly";
 }


  fetchNft = async() =>{
    const{tokenId} = this.state
    try {
      let NftData = await api.getNft({tokenId});
      // console.log(NftData);
      let{nft,collection,author} = NftData.data;
      this.setState({nft,collection,author})
      this.fetchMarketDetails()
      this.fetchHistory()
      document.title = nft.name;
    } catch (e) {
      let msg = e?.response?.data?.message??"NFT details not found";
      Utils.setToastAlert(msg,"error");
      // console.error(e);
    }
  }
  fetchMarketDetails = async() =>{
    const{tokenId} = this.state

    try {
      let instanceMarket = await tronWeb.contract().at(Utils.marketContract);
      let result = await instanceMarket.auctionMeta(tokenId).call();

      if(result[0] !== "410000000000000000000000000000000000000000"){
        let data = {
          owner:tronWeb.address.fromHex(result[0]),
          price:tronWeb.toDecimal(result[1]),
          time:tronWeb.toDecimal(result[2]),
          bidder:tronWeb.address.fromHex(result[3]),
          bid:tronWeb.toDecimal(result[4]),
          inMarket:true,
        }
        this.setState(data)
      }

      if(result[0] === "410000000000000000000000000000000000000000"){
        let owner = await this.getKnftOwner(tokenId)
        // console.log(owner);
        this.setState({owner})
      }
    } catch (e) {
      // console.log('error occured');
      // console.log(e);
    }
  }
  getKnftOwner = async(tokenId) =>{
    try {
      // console.log("getKnftOwner");
      let instanceNFT = await tronWeb.contract().at(Utils.NftContract)
      let owner = await instanceNFT.ownerOf(tokenId).call();
      return tronWeb.address.fromHex(owner);
    } catch (e) {
      // console.log(e);
      if(e === "REVERT opcode executed") {
        return "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
      }else {
        return "";
      }
    }
  }


  fetchHistory = async() =>{
    let{tokenId} = this.state;
    try {
      let history  = await api.getEvents({tokenId,nftAddress:Utils.NftContract});
      history = history.data.result;
      history = history.sort((a, b) => new Date(b.createdAt).getTime() -  new Date(a.createdAt).getTime());

      let data = {history}
      this.setState(data)
    } catch (e) {
      // console.log(e);
      // console.log(e.response);
    }finally{
      this.setState({isHistory:true})
    }
  }

  handleReloadDetails = async() => {
    try {
      const{tokenId} = this.state
      let instanceMarket = await tronWeb.contract().at(Utils.marketContract);
      let result = await instanceMarket.auctionMeta(tokenId).call();
      if(result[0] !== "410000000000000000000000000000000000000000"){
        let data = {
          owner:tronWeb.address.fromHex(result[0]),
          price:tronWeb.toDecimal(result[1]),
          time:tronWeb.toDecimal(result[2]),
          bidder:tronWeb.address.fromHex(result[3]),
          bid:tronWeb.toDecimal(result[4]),
          inMarket:true,
          isPopUp:false
        }
        this.setState(data)
      }else {
        let instanceNFT = await tronWeb.contract().at(Utils.NftContract)
        let owner = await instanceNFT.ownerOf(tokenId).call();
        this.setState({
          owner:tronWeb.address.fromHex(owner),
          price:0,
          time:0,
          bid:0,
          inMarket:false,
          isPopUp:false
        })
      }
    } catch (e) {
      Utils.setToastAlert("Failed to reload NFT details");
    }
  }


  redirectIkCol = () =>{
    let{nft,collection} = this.state;
    window.location.href =`/mintable/${collection.collectionId}/gallery?s=${nft.tokenId}`
  }


  render() {
    let {address,tokenId,owner,time,bid,price,bidder,inMarket,isPopUp,collection} = this.state;

    let isOwner = address && address === owner?true:false;
    let timeNow = Math.floor(Date.now()/1000);
    let canOwnerCancel = !time || !bid || (time < timeNow && bid < price)?true:false;
    let canOwnerRelease =  !!bid;
    let canUserRelease = time && time <= timeNow && address === bidder && bid >= price;

    let ikMintable = owner === Utils.Zero_address && !!collection.collectionId;

    return (
      <MarketBody
        data={this.state}
        >


          {!!address && !inMarket &&( <div className="market__btns">
            {!!isOwner && <SubmitBtn fullView={true} onClick={()=>this.setState({isPopUp:true})}>Put on sale</SubmitBtn>}
            {!isOwner && !ikMintable && <SubmitBtn fullView={true} disabled>Not for sale</SubmitBtn>}
            {!isOwner && ikMintable && <SubmitBtn fullView={true} onClick={this.redirectIkCol}>Mint Now</SubmitBtn>}

          </div>)}

          {!!address && !!inMarket && (<Fragment>

            {isOwner && <div className="market__btns">
              {canOwnerCancel && <Tasks.CancelAuction data={{tokenId,owner,fullView:!canOwnerRelease}} reFetch={this.handleReloadDetails}/>}
              {canOwnerRelease && <Tasks.AcceptAuction data={{tokenId,fullView:!canOwnerCancel}} reFetch={this.handleReloadDetails} />}
            </div>}

            {!isOwner && (<div className="market__btns">
            {!canUserRelease && !time && !!price && <Tasks.BuyFixedNft data={{tokenId,owner,price,fullView:true}} reFetch={this.handleReloadDetails}/>}
            {!!canUserRelease && <Tasks.AcceptAuction data={{tokenId,fullView:true}} reFetch={this.handleReloadDetails}/>}
            {!canUserRelease && (!!time || !price) && <SubmitBtn fullView={true} onClick={()=>this.setState({isPopUp:true})} reFetch={this.handleReloadDetails}>Place bid</SubmitBtn>}
            </div>)}

          </Fragment>)}

          {isPopUp && !isOwner && <Tasks.ModelBid
            close={()=>this.setState({isPopUp:false})}
            data={{tokenId,owner,price,time,bid,bidder}}
            reFetch = {this.handleReloadDetails}

            />}
          {isPopUp && isOwner && <Tasks.InsertAuction
            close={()=>this.setState({isPopUp:false})}
            reFetch = {this.handleReloadDetails}
            data={{tokenId,owner}}
            />}
      </MarketBody>
    )
  }
}

function NftDetailsWithProps(props){
    const params = useParams();
    let {tokenId} = params || {};
    return (
      <NftDetails
          {...props}
          tokenId={tokenId}
      />
    );
}

export default NftDetailsWithProps
