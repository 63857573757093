import React, {useState, useEffect,Component} from 'react'
import api from "../../api"
import NftCard,{NftCardDummy} from "../nftCard/index.jsx"

import Carousel from "../../reuseable/carousel"


const CarouselAuction = (props) =>{

  const responsive = {
    576 : {items: 1,margin:8},
    768 : {items: 2,margin:10},
    992 : {items: 3,margin:8},
    1200 : {items: 4,margin:10,mouseDrag: false,dots: false},
  }
  const [collections, setCollections] = useState([]);

  const fetchCollection = async () =>{
    try {
      let {nftAddress,collectionId,creator} = props?.data??{};
      let limit = window.innerWidth < 768?6:12;
      let res = await api.getSimilarNft({nftAddress,collectionId,creator,limit});
      setCollections(res?.data?.result??[])
    } catch (e) {
      // console.log(e.response);
    }
  }

  useEffect(() => {
    fetchCollection();
  }, []);

  const renderCollection = () =>{
    let layout = {isSlider:true}
    if(!collections || !collections.length){
      return new Array(4).fill(undefined).map((_,i)=><NftCardDummy layout={layout} key={i}/>)
    }
    return collections.map(data=><NftCard data={data} layout={layout} key={data.nftAddress+data.tokenId}/>)
  }

  return(
    <Carousel
      mouseDrag= {true}
      touchDrag= {true}
      dots= {true}
      autoplay= {true}
      autoplayHoverPause= {true}
      autoplayTimeout= {4000}
      smartSpeed= {1000}
      responsive= {responsive}
    >
      {renderCollection()}
    </Carousel>
  )
}


export default CarouselAuction
