import React, { useState,useEffect,Fragment} from 'react'
import styled from 'styled-components'

import {Link} from 'react-router-dom'
import {Utils,PopUp} from "../../helper"
import {DpViewer,NftViewer,SmLoader} from "../../helper/file-viewer.jsx"
import VideoPlayer from '../../helper/video-player/index.jsx';

import CarouselAuction from "../../components/carousel-auction/index.jsx"
import $ from "jquery";
import api from "../../api"
import {ReactComponent as Verified} from '../../images/verified_star.svg';
import {ReactComponent as Blocked} from '../../images/banned.svg';

import {ReactComponent as TransferIcon} from '../../images/transfer.svg';
import {ReactComponent as LockedIcon} from '../../images/locked.svg';
import {ReactComponent as UnlockedIcon} from '../../images/unlocked.svg';
import {ReactComponent as MenuIcon} from '../../images/square-menu.svg';
import {ReactComponent as Info} from '../../images/info.svg';
import {ReactComponent as Desc} from '../../images/desc.svg';
import {ReactComponent as Tag} from '../../images/tag.svg';
import {ReactComponent as List} from '../../images/list.svg';
import {ReactComponent as Maximise} from '../../images/maximise.svg';
import {ReactComponent as FolderIcon} from '../../images/files.svg';
import {ReactComponent as PdfIcon} from '../../images/pdf.svg';
import {ReactComponent as ImageIcon} from '../../images/image.svg';
import {ReactComponent as AudioIcon} from '../../images/audio.svg';
import {ReactComponent as videoIcon} from '../../images/video.svg';

import tronWeb from "../../tronweb"


import {
  FacebookShareButton,
  // TelegramShareButton,
  TwitterShareButton,
} from "react-share";



const StyledLink  = styled.a.attrs({
    target : "_blank",
    rel : "noopener noreferrer"
})``;

const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?"full__btn":props.largeView?"large__btn":props.smfullView?"sm__full__btn":props.smlargeView?"sm__large__btn":""}`,
  type:"button"
}))``

class OwnerOptions extends React.Component{
  constructor(props) {
      super(props)

      this.state = {
        openMenu:false,
        task:"",
        toAddress:"",
        isProcess: false,
      }
  }
  handleMenuPopup = () =>{
    let{openMenu,isProcess} = this.state;
    if(isProcess) return Utils.setToastAlert("Previous request is in process");
    this.setState({openMenu:!openMenu,task:""})
  }
  handleTask = () =>{
    let{openMenu,isProcess} = this.state;
    if(isProcess) return Utils.setToastAlert("Previous request is in process");
    this.setState({openMenu:!openMenu})
  }
  getNftOwner = async(tokenId,nftAddress = Utils.NftContract) =>{
    try {
      let instanceNFT = await tronWeb.contract().at(nftAddress)
      let owner = await instanceNFT.ownerOf(tokenId).call();
      owner = owner._owner?owner._owner:owner;
      return tronWeb.address.fromHex(owner);
    } catch (e) {
      // console.log(e);
        return "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb";
    }
  }
  handleNftBurn = async() =>{
    try {
      let{isProcess} = this.state;
      let {tokenId,nftAddress} = this.props.data;
      if(isProcess) return Utils.setToastAlert("Previous request is in process");
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();

      this.setState({isProcess:true});

      let owner = await this.getNftOwner(tokenId,nftAddress);
      if(owner === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not minted or burned, Only metadata is available in records");
      }
      if(owner !== address){
        this.setState({isProcess:false})
        return Utils.setToastAlert("looks like, You are not owner of this NFT");
      }

      let options = {
        feeLimit:100 * 1e6,
        callValue:0,
      }


      let instanceNFT = await window.tronWeb.contract().at(nftAddress);
      await instanceNFT.burn(tokenId).send(options);

      Utils.setToastAlert("NFT successfully Burned","success")
      this.setState({isProcess:false,openMenu:false,task:""})
    } catch (e) {
      this.setState({isProcess:false})
      console.log(e);
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined")
      }else {
        Utils.setToastAlert("Failed to burn NFT","error")
      }
    }
  }
  handleTransferNft = async() =>{
    try {
      let{toAddress,isProcess} = this.state;
      let {tokenId,nftAddress} = this.props.data

      if(isProcess) return Utils.setToastAlert("Previous request is in process");
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();

      if(!toAddress || toAddress.length !== 34 || !tronWeb.isAddress(toAddress)) return Utils.setToastAlert("Invalid Receiving Account");
      this.setState({isProcess:true});

      let owner = await this.getNftOwner(tokenId,nftAddress);


      if(owner === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not minted or burned, Only metadata is available in records");
      }

      if(owner !== address){
        this.setState({isProcess:false})
        return Utils.setToastAlert("looks like, You are not owner of this NFT");
      }

      let options = {
        feeLimit:100 * 1e6,
        callValue:0,
      }
      let instanceNFT = await window.tronWeb.contract().at(nftAddress);
      await instanceNFT.transferFrom(address,toAddress,tokenId).send(options);

      Utils.setToastAlert("NFT successfully Transfered","success")
      this.setState({isProcess:false,openMenu:false,task:""})
    } catch (e) {
      this.setState({isProcess:false})
      // console.log(e);
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined")
      }else {
        Utils.setToastAlert("Failed to transfer NFT","error")
      }
    }
  }
  render(){
    let{openMenu,task,toAddress,isProcess} = this.state;
    let {nftAddress} = this.props.data

    return (
      <Fragment>
        <button className="market__preview__social__btn" onClick={this.handleMenuPopup}>
          <MenuIcon />
            <div className="tooltip">
              <p className="tooltip__text">Burn/Transfer</p>
            </div>
        </button>
        {!!openMenu && <PopUp.PopupContainer
          onClose={this.handleMenuPopup}
          title="Other Action"
          >
            <div className="owner__option__panel">
              <div className="sign__form__ik_collection">
                <div className="row no-gutters">

                  {!task && <div className="col-6">
                    <div className="owner__nft__option" onClick={()=>this.setState({task:"burn"})}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.46777,8.39453l-.00225.00183-.00214.00208ZM18.42188,8.208a1.237,1.237,0,0,0-.23-.17481.99959.99959,0,0,0-1.39941.41114,5.78155,5.78155,0,0,1-1.398,1.77734,8.6636,8.6636,0,0,0,.1333-1.50977,8.71407,8.71407,0,0,0-4.40039-7.582,1.00009,1.00009,0,0,0-1.49121.80567A7.017,7.017,0,0,1,7.165,6.87793l-.23047.1875a8.51269,8.51269,0,0,0-1.9873,1.8623A8.98348,8.98348,0,0,0,8.60254,22.83594.99942.99942,0,0,0,9.98,21.91016a1.04987,1.04987,0,0,0-.0498-.3125,6.977,6.977,0,0,1-.18995-2.58106,9.004,9.004,0,0,0,4.3125,4.0166.997.997,0,0,0,.71534.03809A8.99474,8.99474,0,0,0,18.42188,8.208ZM14.51709,21.03906a6.964,6.964,0,0,1-3.57666-4.40234,8.90781,8.90781,0,0,1-.17969-.96387,1.00025,1.00025,0,0,0-.79931-.84473A.982.982,0,0,0,9.77,14.80957a.99955.99955,0,0,0-.8667.501,8.9586,8.9586,0,0,0-1.20557,4.71777,6.98547,6.98547,0,0,1-1.17529-9.86816,6.55463,6.55463,0,0,1,1.562-1.458.74507.74507,0,0,0,.07422-.05469s.29669-.24548.30683-.2511a8.96766,8.96766,0,0,0,2.89874-4.63269,6.73625,6.73625,0,0,1,1.38623,8.08789,1.00024,1.00024,0,0,0,1.18359,1.418,7.85568,7.85568,0,0,0,3.86231-2.6875,7.00072,7.00072,0,0,1-3.2793,10.457Z"/></svg>
                      <p>Burn</p>
                    </div>
                  </div>}
                  {!task && <div className="col-6">
                    <div className="owner__nft__option" onClick={()=>this.setState({task:"send"})}>
                      <TransferIcon />
                        <p>Transfer</p>
                    </div>
                  </div>}

                  <div className="col-12">
                    {task === "send" &&<div className="sign__group">
                      <label className="sign__label" htmlFor="tron">Receiving Account</label>
                      <input
                        type="text"
                        name="tron"
                        className="sign__input"
                        placeholder="Paste or enter receiving address"
                        value={toAddress}
                        onChange={(e)=>this.setState({toAddress:e.target.value})}
                        />
                    </div>}
                  {task === "burn" && <p className="nft__burn__info">The NFT will disappear from your collection and will be burned forever. However the undestructable Metadata will keep existing.</p>}

                  </div>

                  <div className="col-12">
                    {!!task && <div className="btn__panel">
                      {task === "burn" && <CreateBtn fullView onClick={this.handleNftBurn} disabled={!nftAddress}>
                      Burn
                      {isProcess && <div className="ball__pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>}
                    </CreateBtn>}
                      {task === "send" && <CreateBtn fullView onClick={this.handleTransferNft} disabled={!nftAddress}>
                      Send
                      {isProcess && <div className="ball__pulse">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>}
                    </CreateBtn>}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
        </PopUp.PopupContainer>}

      </Fragment>
    )
  }
}

const SocialShare = (props) => {


  let {name,nftAddress,tokenId} = props.data
  let _marketUrl = !nftAddress || nftAddress === Utils.NftContract?`market/${tokenId}`:`market3p/${nftAddress}/${tokenId}`;
  _marketUrl = `https://kraftly.io/${_marketUrl}`;

  const title = `${name}`;

  let handleCopyLink = (e) =>{
    e.preventDefault();

    var textField = document.createElement('textarea')
    textField.innerText = _marketUrl;
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove();

    /* Alert the copied text */

    let _tooltip = e.currentTarget.querySelector('.tooltip').classList;
    _tooltip.add("active");
    let _tooltipText = e.currentTarget.querySelector('.tooltip__text');
    _tooltipText.innerText = "Copied"


    setTimeout(function() {
      _tooltip.remove("active");
      _tooltipText.innerText = "Copy"
    }, 3000);


  }
  return <div className="market__preview__social">


    <FacebookShareButton
      url={_marketUrl}
      quote={title}
      disabled={!name}
      >
        <div className="market__preview__social__btn market__preview__social__btn_fb" >
          <svg width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.56341 16.8197V8.65888H7.81615L8.11468 5.84663H5.56341L5.56724 4.43907C5.56724 3.70559 5.63693 3.31257 6.69042 3.31257H8.09873V0.5H5.84568C3.1394 0.5 2.18686 1.86425 2.18686 4.15848V5.84695H0.499939V8.6592H2.18686V16.8197H5.56341Z"/></svg>
            <div className="tooltip">
              <p className="tooltip__text">Share on Facebook</p>
            </div>
        </div>
    </FacebookShareButton>
    <TwitterShareButton
      url={_marketUrl}
      title={title}
      disabled={!name}
      >
        <div  className="market__preview__social__btn market__preview__social__btn_tw">
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.55075 3.19219L7.58223 3.71122L7.05762 3.64767C5.14804 3.40404 3.47978 2.57782 2.06334 1.1902L1.37085 0.501686L1.19248 1.01013C0.814766 2.14353 1.05609 3.34048 1.843 4.14552C2.26269 4.5904 2.16826 4.65396 1.4443 4.38914C1.19248 4.3044 0.972149 4.24085 0.951164 4.27263C0.877719 4.34677 1.12953 5.31069 1.32888 5.69202C1.60168 6.22165 2.15777 6.74068 2.76631 7.04787L3.28043 7.2915L2.67188 7.30209C2.08432 7.30209 2.06334 7.31268 2.12629 7.53512C2.33613 8.22364 3.16502 8.95452 4.08833 9.2723L4.73884 9.49474L4.17227 9.8337C3.33289 10.321 2.34663 10.5964 1.36036 10.6175C0.888211 10.6281 0.5 10.6705 0.5 10.7023C0.5 10.8082 1.78005 11.4014 2.52499 11.6344C4.75983 12.3229 7.41435 12.0264 9.40787 10.8506C10.8243 10.0138 12.2408 8.35075 12.9018 6.74068C13.2585 5.88269 13.6152 4.315 13.6152 3.56293C13.6152 3.07567 13.6467 3.01212 14.2343 2.42953C14.5805 2.09056 14.9058 1.71983 14.9687 1.6139C15.0737 1.41264 15.0632 1.41264 14.5281 1.59272C13.6362 1.91049 13.5103 1.86812 13.951 1.39146C14.2762 1.0525 14.6645 0.438131 14.6645 0.258058C14.6645 0.22628 14.5071 0.279243 14.3287 0.374576C14.1398 0.480501 13.7202 0.639389 13.4054 0.734722L12.8388 0.914795L12.3247 0.565241C12.0414 0.374576 11.6427 0.162725 11.4329 0.0991699C10.8978 -0.0491255 10.0794 -0.0279404 9.59673 0.14154C8.2852 0.618204 7.45632 1.84694 7.55075 3.19219Z"/></svg>
            <div className="tooltip">
              <p className="tooltip__text">Share on Twitter</p>
            </div>
        </div>
    </TwitterShareButton>

    <button className="market__preview__social__btn" onClick={handleCopyLink} disabled={!name}>
      <svg width="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M8,12a1,1,0,0,0,1,1h6a1,1,0,0,0,0-2H9A1,1,0,0,0,8,12Zm2,3H7A3,3,0,0,1,7,9h3a1,1,0,0,0,0-2H7A5,5,0,0,0,7,17h3a1,1,0,0,0,0-2Zm7-8H14a1,1,0,0,0,0,2h3a3,3,0,0,1,0,6H14a1,1,0,0,0,0,2h3A5,5,0,0,0,17,7Z"/>
      </svg>
      <div className="tooltip">
        <p className="tooltip__text">Copy</p>
      </div>
    </button>
    <OwnerOptions data={props.data}/>

    <button className="market__preview__social__btn" onClick={props.handleFullScreen} disabled={!name}>
      <Maximise/>
        <div className="tooltip">
          <p className="tooltip__text">Full Size</p>
        </div>
    </button>
  </div>;

}

class Likes extends React.Component{

  constructor(props) {
      super(props)
      this.state = {
        isProcess:false,
        address:"",
        likes:this.props.data.likes || 0,
      }
  }
  handleLike = async() =>{
    let{isProcess,likes} = this.state;
    let{tokenId,nftAddress} = this.props.data
    try {
      let address = Utils.viewCreator();
      if(!address) return $(".header__action-btn--signin").click();
      if(isProcess) return Utils.setToastAlert("Previous request is in process");
      let payload = {tokenId,address,nftAddress}
      this.setState({isProcess:true})
      let liked = Utils.updateLike(nftAddress+tokenId)
      await api.likeNft(payload)
      likes = liked?likes+1:likes-1
      this.setState({isProcess:false,likes})
    } catch (e) {
      console.log(e.response);
      Utils.updateLike(nftAddress+tokenId)
      this.setState({isProcess:false})
    }
  }
  render(){
    let{likes} = this.state;
    let{tokenId,name,nftAddress,createdAt} = this.props.data;

    let _liked = Utils.viewCreator("liked").findIndex(i=> i === nftAddress+tokenId) !== -1;
    return(
      <Fragment>
        {!!name && <button
          className={`market__likes ${_liked?"market__likes__active":""}`}
          type="button"
          disabled={!name || !createdAt}
          onClick={this.handleLike}
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            {!_liked && <path d="M20.16,5A6.29,6.29,0,0,0,12,4.36a6.27,6.27,0,0,0-8.16,9.48l6.21,6.22a2.78,2.78,0,0,0,3.9,0l6.21-6.22A6.27,6.27,0,0,0,20.16,5Zm-1.41,7.46-6.21,6.21a.76.76,0,0,1-1.08,0L5.25,12.43a4.29,4.29,0,0,1,0-6,4.27,4.27,0,0,1,6,0,1,1,0,0,0,1.42,0,4.27,4.27,0,0,1,6,0A4.29,4.29,0,0,1,18.75,12.43Z"/>}
            {!!_liked && <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>}
          </svg>
          <span>{likes || 0}</span>
        </button>}
        {!name && <span className="market__likes dummy__space"> </span>}
      </Fragment>
    )
  }
}

const NftPreview = (props) => {
  const[isShow,setShow] = useState(false);


  let{description,name,creator,owner,tokenId,url,likes,isBanned,baseurl,cid,formate,nftAddress,createdAt,imgData} = props.data.nft
  return <div className="market__preview">
    <div className="market__preview__body">
      <div className={`market__preview__cover ${!name?"dummy__space":""}`}>
        {!!name && <NftViewer data={{url,isBanned,cid,baseurl,formate,imgData}} />}
      </div>
      {!props.data.mustHide && <div className="market__preview__foot">
        <SocialShare
          data={{description,name,creator,tokenId,owner,nftAddress}}
          handleFullScreen={()=>setShow(!isShow)}
          />
        <Likes data={{likes,name,tokenId,nftAddress,createdAt}} key={likes+tokenId+nftAddress}/>
      </div>}

      {!!isShow && !!name && <PopUp.PopupContainer
        className="nft__full__preview"
        onClose={()=>setShow(false)}
        title="Preview Item"
        >
        <div className="market__preview__popup__cover">
          <NftViewer data={{url,isBanned,cid,baseurl,formate}} />
        </div>
      </PopUp.PopupContainer>}
    </div>
  </div>
}



const RankRarity = (props) => {
  let {rank,rarity,name} = props?.data?.nft??{};
  return (
    <Fragment>
      {!!(!name || !!rank) && <div className={`rank__rarity ${!rank?"dummy__space":""}`}>
        <div className="rank__rarity__score">
          {!!rank && <p><span>Rank :</span> <span>{rank}</span></p>}
          {!!rarity && <p><span>Rarity Score :</span> <span>{rarity}</span></p>}
          {!name && <p><span>&nbsp;</span></p>}
          {!name && <p><span>&nbsp;</span></p>}
        </div>
      </div>}
    </Fragment>
  )
}

const AttributesViewer = (props) => {
  let {attributes,name} = props?.data?.nft??{};
  attributes = attributes || [];
  const[isShow,setShow] = useState(window.innerWidth > 992);

  const renderAttrDummy = () =>{
    return new Array(6).fill(undefined).map((_,i)=>{
      return <div className="market__attrs__each dummy__space" key={i}>
        <div className="market__attrs__each__body">
          <p>&nbsp;</p>
          <span>&nbsp;</span>
        </div>
      </div>
    })
  }
  const renderAttr = () =>{
    return attributes.map((item,i)=>{
      let{name,value} = item;
      return <div className="market__attrs__each" key={name+value+i}>
        <div className="market__attrs__each__body">
          <p>{name}</p>
          <span>{value}</span>
        </div>
      </div>
    })
  }
  return(
    <Fragment>
      {!!(!name || !!attributes.length) && <div className="market__section">
        <div className="market__section__body">
          <div className="market__section__head" onClick={()=>setShow(!isShow)}>
            <h2><Tag/>Attributes</h2>
            <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
          </div>
          {!!isShow && <div className="market__attr">
            {!!attributes.length && renderAttr()}
            {!attributes.length && renderAttrDummy()}
          </div>}
        </div>
      </div>}
    </Fragment>
  )
}


class UnlockableContent extends React.Component{
  constructor(props) {
      super(props)
      this.state = {
        isOpen:false,
        isProcess:false,
        content:"",
      }
      this.canvasRef = React.createRef();
    }


    handleOpen = () =>{
      let{formate} = this.props.data;
      let{isProcess} = this.state;
      if(!!isProcess) return console.log("processing");
      this.setState({isProcess:true});

      if(formate === "pdf"){
        this.renderPdf()
      }else {
        this.renderFile()
      }

    }
    renderFile = () =>{
      let{formate,url} = this.props.data;
      let _compResult = document.createElement("img");

      if(formate === "video" || formate === "audio"){
        _compResult = document.createElement(formate);
        _compResult.autoplay = true;
        _compResult.controls = true;
      }
      _compResult.src = url;
      this.setState({isProcess:false,content:true},()=>{
        this.canvasRef.current.appendChild(_compResult)
      });
    }
    renderPdf = async() =>{
      try {
        let{url} = this.props.data;

        const pdfJS = await require('pdfjs-dist/build/pdf');
  			pdfJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/pdf.worker.min.js';

        // console.log(pdfJS);
  			const pdf = await pdfJS.getDocument(url).promise;
        this.setState({isProcess:false,content:true});
        const page = await pdf.getPage(1);
        let _width = this.canvasRef.current.offsetWidth;
  			let viewport = page.getViewport({ scale:1});
        let scale = _width/viewport.width;
        viewport = page.getViewport({ scale});

        _setUp()
        async function _setUp(_index = 1){
          try {
            if(_index > pdf.numPages) return;
            const eachPage = await pdf.getPage(_index);
            // Prepare canvas using PDF page dimensions.
            const canvas = document.createElement('canvas');
      			const canvasContext = canvas.getContext('2d');
      			canvas.height = viewport.height;
      			canvas.width = _width;

      			// Render PDF page into canvas context.
      			const renderContext = { canvasContext, viewport };
      			await eachPage.render(renderContext);
            document.getElementById('content__viewport').appendChild(canvas);
            _setUp(_index+1)
          } catch (e) {
          // console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
        this.setState({isProcess:false,content:""})
      }
    }

  render(){
    let{locked,formate} = this.props.data;
    let{isOpen,isProcess,content} = this.state;
    let isOwner = !locked || this.props.isOwner;
    // isProcess = true;
    let _contentIcon = formate === "pdf"?PdfIcon:formate === "audio"?AudioIcon:formate === "video"?videoIcon:ImageIcon;
    return(
      <Fragment>
        <div className="unlockable__content" onClick={()=>this.setState({isOpen:!isOpen})}>
          <div className="unlockable__content__body">
            <p className="unlockable__content__type">{!!locked?<LockedIcon/>:<UnlockedIcon/>}</p>
            <p className="unlockable__content__value">{!!locked?"Unlockable":"Viewable"} content</p>
          </div>
        </div>
        {!!isOpen && !content && <PopUp.PopupContainer
          title={!!locked?"Unlockable content":"Additional Content"}
          onClose={()=>this.setState({isOpen:!isOpen})}>

          {!!isOwner && <div className="unlockable__content__msg">
          {!isProcess && <_contentIcon />}
          {!!isProcess && <SmLoader/>}

            {!isProcess && <div className="btn__panel">
              <div className="sign__btn"  onClick={this.handleOpen}>
                View Content
              </div>
            </div>}
          </div>}

         {!isOwner && <div className="unlockable__content__msg">
           This content can only be unlocked and revealed by the owner of this item.
         </div>}
       </PopUp.PopupContainer>}

       {!!isOpen && !!content && <PopUp.PopupContainer
         title="Conetnt"
         className="fullscr__popup"
         onClose={()=>this.setState({isOpen:!isOpen,content:""})}>
         <div className="content__viewport" id="content__viewport" ref={this.canvasRef}>
         </div>
       </PopUp.PopupContainer>}


      </Fragment>

    )
  }

}
const UnlockableModel = (props) =>{

  const[isShow,setShow] = useState(window.innerWidth > 992);
  let {unlockable,name,creator} = props?.data?.nft??{};
  unlockable = unlockable || [];

    const  renderContent = () =>{
      let{address,owner} = props?.data??{};
      let isOwner = !!address && (address === owner || address === creator);

      return unlockable.map(item=><UnlockableContent data={item} isOwner={isOwner} key={item.url}/>)
    }


    return(
      <Fragment>
        {!!(!name || !!unlockable.length) && <div className="market__section">
          <div className="market__section__body">
            <div className="market__section__head" onClick={()=>setShow(!isShow)}>
              <h2><FolderIcon/> Additional Content</h2>
              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
            </div>
           {!!isShow && !!unlockable.length && renderContent()}
           {!!isShow && !name && <div className="unlockable__content dummy__space">&nbsp;</div>}
         </div>
        </div>}
      </Fragment>
    )
}


const NftMintDetails = (props) => {
  const[isShow,setShow] = useState(window.innerWidth > 992);

  let {author,nft,nftDoc,collection} = props.data;
  author = author || {};
  nft = nft || {};
  nftDoc = nftDoc || {};
  collection = collection || {};

  let {tags,createdAt,baseurl,cid,nftAddress,adult,elink} = nft;

  tags = tags&&tags.length?tags:["#NFT"];
  tags = tags.map((_i,i)=>{
    return <Link className="tags__each" to={`/search/${_i.substring(1)}?page=1&type=nfts`} key={i}>{_i}</Link>
  });


  let storageName = baseurl === "https://gateway.btfs.io/btfs/"?"BTFS":"IPFS";
  baseurl = baseurl === "https://gateway.btfs.io/btfs/"?baseurl:"https://ipfs.io/ipfs/";

  return <div className="market__section">

    <div className="market__section__body">
      <div className="market__section__head" onClick={()=>setShow(!isShow)}>
        <h2><Info/>Details</h2>
        <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
      </div>

      {!!isShow && !!nft.name && <ul className="market__details">
        {!!createdAt && <li>
          <span>Creation time</span>
          <p>{Utils.getTimeInGMT(createdAt)}</p>
        </li>}
        {!!author.name && <li>
          <span>Created By</span>
          <p>
            <Link to={`/author/${author.address}/profile?tab=created`}>{author.name}</Link>
            {!!author.verified && <Verified/>}
            {!!author.blocked && <Blocked/>}
          </p>
        </li>}
        {!!nftDoc.name && <li>
          <span>NFT Contract</span>
          <p>
            <Link to={`/mintable/trc721/${nftAddress}`}>{nftDoc.name}</Link>
            {!!nftDoc.verified && <Verified/>}
            {!!nftDoc.blocked && <Blocked/>}
          </p>
        </li>}
        {!!collection.name && <li>
          <span>Collection</span>
          <p>
            <Link to={`/mintable/${collection.collectionId}/gallery`}>{collection.name}</Link>
            {!!collection.verified && <Verified/>}
            {!!collection.blocked && <Blocked/>}
          </p>
        </li>}
        <li>
          <span>Royality</span>
          <p>{Utils.preety.ceil(nftDoc.royaltyAmount/100,1)}%</p>
        </li>
        {!!nft.tokenId && <li>
          <span>Token Id</span>
          <p>{nft.tokenId}</p>
        </li>}
        <li>
          <span>NSFW</span>
          <p>{!!adult?"yes":"No"}</p>
        </li>
        <li>
          <span>Tags</span>
          <p className="tags">{tags}</p>
        </li>
        {!!cid && <li>
          <span>Decentralise Storage</span>
          <p className="storage__url">
            <StyledLink href={`${baseurl}${cid}`} >{storageName}</StyledLink>
          </p>
        </li>}
        {!!elink && <li>
          <span>External Link</span>
          <p className="storage__url">
            <StyledLink href={elink} >Click here</StyledLink>
          </p>
        </li>}
      </ul>}


      {!!isShow && !nft.name && <ul className="market__details dummy__space">
        <li><span>Creation time</span><p>&nbsp;</p></li>
        <li><span>Created By</span><p>&nbsp;</p></li>
        <li><span>NFT Contract</span><p>&nbsp;</p></li>
        <li><span>Collection</span><p>&nbsp;</p></li>
        <li><span>Royality</span><p>&nbsp;</p></li>
        <li><span>Token Id</span><p>&nbsp;</p></li>
        <li><span>NSFW</span><p>&nbsp;</p></li>
        <li><span>Tags</span><p className="tags">
          {new Array(5).fill(undefined).map((_,i)=><span className="tags__each" key={i}>&nbsp;</span>)}
        </p></li>
      </ul>}

    </div>
  </div>;
}






/*
* Right side of marketplace page
*
*
*
*
*/

const Description = (props) => {
  let {description,name} = props?.data?.nft??{};
  description = !!name && !description ? "No description": description;

  return <div className="market__section">
    <div className="market__section__body">
      <div className="market__section__head"><h2><Desc/> Descriptions</h2></div>
      <p className={`market__desc ${!name?"dummy__space":""}`}>{description}</p>
    </div>
  </div>
}



/*
* NftHistory
* it will display all activity history of nft market place
* and meet necessary condition
*/

const AddressView = (props) =>{

  let btnRef = React.createRef();

  const handleLinkCopy = () =>{
   // console.log("btn");
   var textField = document.createElement('textarea')
   textField.innerText = props.address;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

   btnRef.current.style.display = "block";
   btnRef.current.children[0].innerText  = "copied"
   /* Alert the copied text */
   setTimeout(function() {
     btnRef.current.children[0].innerText  = "Copy"
     btnRef.current.style.display = "";
   }, 3000);
  }

  return (
    <div className="viewable__address">
      <Link className="gradient__address"  to={`/author/${props.address}/profile?tab=collection`}>{Utils.shortAddress(props.address,true)}</Link>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={handleLinkCopy}><path d="M18,19H6a3,3,0,0,1-3-3V8A1,1,0,0,0,1,8v8a5,5,0,0,0,5,5H18a1,1,0,0,0,0-2Zm5-9.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L16.06,3H8A3,3,0,0,0,5,6v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V10S23,10,23,9.94ZM17,6.41,19.59,9H18a1,1,0,0,1-1-1ZM21,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V6A1,1,0,0,1,8,5h7V8a3,3,0,0,0,3,3h3Z"/></svg>
        <div className="tooltip" ref={btnRef}>
          <p className="tooltip__text">Copy</p>
        </div>
    </div>
  )
}


const NftHistory =(props)=> {
  const[isShow,setShow] = useState(true);
  const[onlyBids,setOnlyBids] = useState(false);

  let{history,isHistory} = props;
  history = history || [];
  let bids = history.filter(i =>i.name && (i.name === "AuctionBid" || i.name === "NewOffer"));

  const dummyRender = () =>{

    return new Array(4).fill(undefined).map((_,i)=>{
      return <div className="market__event dummy__space" key={i}>
      <div className="market__event__avatar">
        <div className="market__event__avatar__body">
          <DpViewer/>
        </div>
      </div>
      <div className="market__event__content">&nbsp;</div>
      </div>
    })
  }

  const renderHistory = () =>{
    return (!!onlyBids?bids:history).map((data,i)=>{
      // console.log(data);
      let pElement = <div className="market__event__content">Loading</div>;

      let{name,price,time,account,timestamp,bidder,bid,owner,currencyId} = data;
      bid = bid && bid > 0? bid:0;
      bid = name === "NFTminted"?bid:name === "NewOffer" || name === "OfferRemoved"?bid / 1.05:bid / 1.025;

      let _currencyName = Utils.getCurrencyName(currencyId)
      let _dpAccount = name === "AuctionOrder"?owner:name === "CancelAuction"?account:bidder;

      if(name === "AuctionOrder"){
        pElement = <div className="market__event__content">
          Put on sale
          {!!time&&!!price?` for minimum bid ${Utils.floor(price/1e6,1)} ${_currencyName} and Expire at ${Utils.getTimeInGMT(time*1000)} `:""}
          {!time&&!!price?` ${Utils.floor(price/1e6,1)} ${_currencyName} `:!price?" as unlimited auction ":""}
          by
          <AddressView address={owner}/> <span>{Utils.getTimeAgo(timestamp)}</span>
        </div>
      }else if (name === "CancelAuction") {
        pElement = <div className="market__event__content">Auction cancelled by <AddressView address={account}/> <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "AuctionBid") {
        pElement = <div className="market__event__content">Bid placed for {Utils.floor(bid/1e6,1)} {_currencyName} by <AddressView address={bidder}/> <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "SoldAuction") {
        pElement = <div className="market__event__content">Bought by <AddressView address={bidder}/> for {Utils.floor(bid/1e6,1)} {_currencyName} from <AddressView address={owner}/> <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "BidRemoved") {
        pElement = <div className="market__event__content"><AddressView address={bidder}/> bid of {Utils.floor(bid/1e6,1)} {_currencyName} removed <span> {Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "NewOffer") {
        pElement = <div className="market__event__content"><AddressView address={bidder}/> offered {Utils.floor(bid/1e6,1)} {_currencyName} to mint <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "OfferRemoved") {
        pElement = <div className="market__event__content"><AddressView address={bidder}/> offer of {Utils.floor(bid/1e6,1)} {_currencyName} removed <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }else if (name === "NFTminted") {
        pElement = <div className="market__event__content">Minted by <AddressView address={bidder}/> for {Utils.floor(bid/1e6,1)} {_currencyName} <span>{Utils.getTimeAgo(timestamp)}</span></div>
      }

      return(<div className="market__event" key={i+timestamp}>
      <div className="market__event__avatar">
        <div className="market__event__avatar__body">
          <DpViewer address={_dpAccount} key={_dpAccount}/>
        </div>
      </div>
        {pElement}
      </div>)
    })
  }
    return (
        <div className="market__section">
          <div className="market__section__body">
            <div className="market__section__head" onClick={()=>setShow(!isShow)}>
              <h2><List/> History</h2>
              <svg viewBox="0 0 96 96" xmlns="http://www.w3.org/2000/svg"><path d="M81.8457,25.3876a6.0239,6.0239,0,0,0-8.45.7676L48,56.6257l-25.396-30.47a5.999,5.999,0,1,0-9.2114,7.6879L43.3943,69.8452a5.9969,5.9969,0,0,0,9.2114,0L82.6074,33.8431A6.0076,6.0076,0,0,0,81.8457,25.3876Z"/></svg>
            </div>
            {!!isShow && <div className="market__history">
              {!!isHistory &&  <div className={`market__history__select ${onlyBids?"active":""}`} onClick={()=>setOnlyBids(!onlyBids)}><p><span>&nbsp;</span></p> only Bids</div>}
              {!!history.length && (!onlyBids || !!bids.length) && renderHistory()}
              {!history.length && !isHistory &&  dummyRender()}

              {(!history.length || (!!onlyBids && !bids.length)) && !!isHistory &&  (<div className="market__event">
                <p  className="market__event__content">No {!onlyBids?"History":"Bids"} yet</p>
              </div>)}
            </div>}

          </div>
        </div>
      )
}



const MarketState = (props) =>{

  let{time,history,isHistory} = props.data??{};
  let usdtRate = Utils.viewUsdtRate();


  useEffect (() => {
    let {time} = props.data;
    // console.log(time);
    let timer = setInterval(function() {
      let leftTime = Utils.getTimeOffset(time, true);
      $(`.market__clock`).text(leftTime);
      if (time <= Math.floor(Date.now() / 1000)) {
        clearInterval(timer);
      }
    }, 1000);
  },[props.data]);


  const priceTag = (_name,_price,_time) =>{
    return _name === "SoldAuction"?"Last Sale":
    _name === "NFTminted"?"Minted":
    _name === "AuctionOrder" && !!_time?"Minimum Bid":
    _name === "AuctionOrder"&& !!_price?"Price":
    _name === "AuctionOrder"?"No Limit sale":
    _name === "AuctionBid"?"Current Bid":
    _name === "BidRemoved"?"Last Bid":
    _name === "NewOffer"?"Current Offer":
    _name === "OfferRemoved"?"Last Offer":

    _name === "LastSale"?"Previous Listing":
    _name === "LastBid"?"Previous Bid":

    _name;
  }


  // calculation for current data

  let {bid:cBid,currencyId:cCurrencyId,name:cName,price:cPrice,time:cTime} = history[0]|| {};
  cBid = cName === "AuctionOrder"?cPrice:cName === "NewOffer" || cName === "OfferRemoved"?cBid / 1.05:cBid / 1.025;
  if(cName === "CancelAuction"){
    cBid = !!history[1].price?history[1].price:history[1].bid/1.025;
    cName = !!history[1].price || !history[1].bid?"LastSale":"LastBid";
  }
  cName = cName === "SoldAuction" || cName === "NFTminted"?"":cName;
  let _canCUsd = !!usdtRate && !!cBid && cCurrencyId === Utils.Zero_address;


  // calculation for last data

  let {bid:lBid,currencyId:lCurrencyId,name:lName} = history.filter(i => i.name === "SoldAuction" || i.name === "NFTminted")[0] || {};
  lBid = lName === "NFTminted"?lBid:lBid / 1.025;
  let _canLUsd = !!usdtRate && !!lBid && lCurrencyId === Utils.Zero_address;

  return (
    <Fragment>
      {!isHistory && <div className="market__action__info dummy__space">
        <div className="market__action__info__each">
          <h4 className="market__stat__type">&nbsp;</h4>
          <div className="market__stat__amount">&nbsp;</div>
        </div>
        <div className="market__action__info__each">
          <h4 className="market__stat__type">&nbsp;</h4>
          <div className="market__stat__amount">&nbsp;</div>
        </div>
      </div>}

      {!!isHistory && !!history.length && (!!cName || !!lName || !!time) && <div className="market__action__info">

        {!!cName && <div className="market__action__info__each">
          <h4 className="market__stat__type">{priceTag(cName,cPrice,cTime)}</h4>
              <div className="market__stat__amount">
                 <div className="tooltip">
                   <span className="tooltip__text">{Utils.getTokenInfo(cCurrencyId).symbol}</span>
                 </div>
                 <img src={Utils.getTokenInfo(cCurrencyId).img} alt={Utils.getTokenInfo(cCurrencyId).symbol} />
                 <h4>{Utils.preety.ceil(cBid/1e6,1)}</h4>{!!_canCUsd && <span>${Utils.largePreety(cBid*usdtRate/1e6,2)}</span>}
            </div>
        </div>}

        {!time && !!lName && <div className="market__action__info__each">
          <h4 className="market__stat__type">{priceTag(lName)}</h4>
              <div className="market__stat__amount">
                 <div className="tooltip">
                   <span className="tooltip__text">{Utils.getTokenInfo(lCurrencyId).symbol}</span>
                 </div>
                 <img src={Utils.getTokenInfo(lCurrencyId).img} alt={Utils.getTokenInfo(lCurrencyId).symbol} />
                 <h4>{Utils.preety.ceil(lBid/1e6,1)}</h4>{!!_canLUsd && <span>${Utils.largePreety(lBid*usdtRate/1e6,2)}</span>}
            </div>
        </div>}

        {!!time && <div className="market__action__info__each">
          <h4 className="market__stat__type">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.3,8.59l.91-.9a1,1,0,0,0-1.42-1.42l-.9.91a8,8,0,0,0-9.79,0l-.91-.92A1,1,0,0,0,4.77,7.69l.92.91A7.92,7.92,0,0,0,4,13.5,8,8,0,1,0,18.3,8.59ZM12,19.5a6,6,0,1,1,6-6A6,6,0,0,1,12,19.5Zm-2-15h4a1,1,0,0,0,0-2H10a1,1,0,0,0,0,2Zm3,6a1,1,0,0,0-2,0v1.89a1.5,1.5,0,1,0,2,0Z"/></svg>
            Auction end in
          </h4>
              <div className="market__stat__amount">
                 <h4 className="market__clock">&nbsp;</h4>
            </div>
        </div>}
      </div>}
    </Fragment>
  )
}

const OtherAuthorAsset =(props) => {
  let {nftAddress,collectionId,creator} = props?.data??{};
  let url = !!collectionId?`/mintable/${collectionId}/gallery`:!!creator?`/author/${creator}/profile?tab=created`:"#";
  return (
    <section className="content__section">
      <div className="row no-gutters">
        <div className="col-12">
          <div className="section__title">
            <a href={url}>More from this {!!collectionId?"collection":"author"}</a>
          </div>
        </div>

        <div className="col-12">
          <CarouselAuction data={{nftAddress,collectionId,creator}} key={nftAddress+collectionId+creator}/>
        </div>
      </div>
    </section>
  )
}

class MarketBody extends React.Component{

  constructor(props) {
      super(props)
      this.state = {
        isMobile:window.innerWidth <= 992,
      }
    }
    componentDidMount(){
      window.addEventListener('resize', this.handleWidth,{passive:true});
    }
    componentWillUnmount(){
      window.removeEventListener('resize', this.handleWidth,{passive:true});
    }
    handleWidth = () =>{
      if(this.state.isMobile !== (window.innerWidth <= 992)){
        this.setState({isMobile:window.innerWidth <= 992})
      }
    }
    render(){
      let{isMobile} = this.state;

      let {nftAddress,address,history,isHistory} = this.props.data;

      let{owner,time,nft,author,collection,nftDoc} = this.props.data;
      let{name} = nft;
      name = name || "";

      return(
        <main className="marketplace">
            <div className="marketplace__body">

              <div className="market__left">
                <NftPreview data={{nft}}/>
                {!isMobile && <RankRarity data={{nft}}/>}
                {!isMobile && <AttributesViewer data={{nft}}/>}
                {!isMobile && <UnlockableModel data={{address,owner,nft}}/>}
                {!isMobile && <NftMintDetails data={{author,nft,nftDoc,collection}}/>}
              </div>



              <div className="market__right">
                <div className={`market__title ${!name?"dummy__space":""}`}>{name}</div>
                {!!name && <div className="market__author">Owned By <Link to={`/author/${owner}/profile?tab=created`}>{Utils.viewOwner(owner)}</Link></div>}
                {!name && <div className="market__author dummy__space">&nbsp;</div>}


                <Description data={{nft}} />
                  <div className="market__action__panel">
                    <div className="market__action__panel__body">
                      <MarketState data={{time,history,isHistory}}/>

                        {!address && <div className={`market__btns ${!nft.name?"dummy__space":""}`}>
                          {!!nft.name && <button className="market__btn" onClick={()=>$(".header__action-btn--signin").click()}>Connect wallet</button>}

                          {!nft.name && <span>&nbsp;</span>}
                        </div>}

                      {this.props.children}


                    </div>
                  </div>

                  {!!isMobile && <RankRarity data={{nft}}/>}
                  {!!isMobile && <AttributesViewer data={{nft}}/>}
                  {!!isMobile && <UnlockableModel data={{address,owner,nft}}/>}
                  {!!isMobile && <NftMintDetails data={{author,nft,nftDoc,collection}}/>}
                <NftHistory history={history} isHistory={isHistory}/>

              </div>
            </div>
            <div className="marketplace__body">
              <OtherAuthorAsset data={{nftAddress,collectionId:nft.collectionId,creator:author.address}}/>
            </div>


        </main>

      )
    }
}






class MarketBodyCreate extends React.Component{

  constructor(props) {
      super(props)
      this.state = {
        isMobile:window.innerWidth <= 992,
      }
    }
    componentDidMount(){
      window.addEventListener('resize', this.handleWidth,{passive:true});
    }
    componentWillUnmount(){
      window.removeEventListener('resize', this.handleWidth,{passive:true});
    }
    handleWidth = () =>{
      if(this.state.isMobile !== (window.innerWidth <= 992)){
        this.setState({isMobile:window.innerWidth <= 992})
      }
    }
    render(){
      let{isMobile} = this.state;
      let {address,owner,nft,author,nftDoc} = this.props.data;

      return(
        <main className="marketplace" style={{marginTop:0}}>
            <div className="marketplace__body">

              <div className="market__left">
                <NftPreview data={{nft,mustHide:true}}/>
                <RankRarity data={{nft}}/>
                <AttributesViewer data={{nft}}/>
                <UnlockableModel data={{address,owner,nft}}/>
              </div>



              <div className="market__right">
                <div className="market__title">{nft.name}</div>
                <div className="market__author">Owned By <Link to={`/author/${owner}/profile?tab=created`}>{Utils.viewOwner(owner)}</Link></div>

                <Description data={{nft}} />
                <NftMintDetails data={{author,nft,nftDoc}}/>

                  <div className="market__action__panel">
                    <div className="market__action__panel__body">
                      {this.props.children}
                    </div>
                  </div>

              </div>
            </div>
        </main>

      )
    }
}

export default MarketBody;

export {
  MarketBodyCreate,
}
