
import React, { Component ,Fragment} from 'react'
import axios from 'axios'
import {Utils} from "../../../../helper"
import api from "../../../../api"

import DotsLoader from "../../../../reuseable/loader/dots"

import "./styles.scss";


class BannerViewer extends Component{
  constructor(props) {
    super(props)
    this.state = {
      imgData:null,
      previewURL:null,
    }
  }
  componentDidMount (){
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setState(state,callback){
    if (!!this.mounted){
      super.setState(state,callback);
    }
  }
  handleFile = async(event) =>{
    try {
      let{isProcess} = this?.props?.data?.collection??{};
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let file = event.target.files[0];
      // console.log(file);
      if(!file || !file.type){
        return Utils.setToastAlert("File not selected");
      }

      const allowedTypes = [
        'image/jpeg',  // JPEG
        'image/png',   // PNG
        // 'image/gif',   // GIF
        // 'image/bmp',   // BMP
        // 'image/webp',  // WEBP
        // 'image/tiff',  // TIFF
        // 'image/svg+xml' // SVG
      ];
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedTypes.includes(file.type)) {
        return Utils.setToastAlert(`Invalid file type`);
      }

      if (!allowedExtensions.includes(fileExtension)) {
        return Utils.setToastAlert(`Can not upload .${fileExtension}, Only support .${fileExtension.join(" .")} files type`);
      }

      if (file && file.size > 5*1024*1024) { //5MB
        return Utils.setToastAlert("File size exceeds 5MB. Please upload a smaller file.B");
      }

      file = new File([file], `image_${Date.now()}.${fileExtension}`, {type: file.type});
      let previewURL = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      this.setState({imgData:file,previewURL});
    } catch (e) {
      Utils.setToastAlert("Unexpexted error occured");
    }
  }

  uploadFile = async() =>{
    let {isProcess} = this.props.data;
    let{imgData,previewURL} = this.state;
    if(!!isProcess) return;
    await this.props.handler(imgData,previewURL);
    this.setState({imgData:null,previewURL:null})
  }
  cancelUpload = () =>{
    let {isProcess} = this.props.data;
    let{imgData} = this.state;
    if(!!isProcess) return;
    this.setState({imgData:null,previewURL:null})
  }
  render(){
    let{imgData,previewURL} = this.state;
    let {data,isOwner,isProcess} = this?.props?.data??{};
    let imgSource = !!imgData?previewURL:data;

    return(
      <div className="content__cover" style={{"--bg": `url(${imgSource})`}}>
        {!!isOwner && <div className="content__cover__owner">
          {!imgData && <label htmlFor="cover-image">Edit Cover</label>}
          {!imgData && <input id="cover-image" type="file" accept="image/*" onChange={this.handleFile}/>}
          {!!imgData && <button onClick={this.cancelUpload}>Cancel</button>}
          {!!imgData && <button onClick={this.uploadFile}>Save Cover {!!isProcess && <DotsLoader/>}</button>}
        </div>}
      </div>
    )
  }
}

class ContentCover extends Component{
  constructor(props) {
    super(props)
    this.state = {
      data:null,
      isProcess:false,
    }
  }
  componentDidMount (){
    this.mounted = true;
    this.fetchFile();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setState(state,callback){
    if (this.mounted){
      super.setState(state,callback);
    }
  }

  fetchFile = async() =>{
    try {
      let {address,files} = this?.props?.data??{};
      files = files??{};

      if(!address && !files.banner) return "";
      let url = Utils.getBanner(address,files?.banner??"")
      this.setState({isProcess:true})
      let _fileData = await api.fileApi.get(url);
      let _url = URL.createObjectURL(_fileData.data);
      this.setState({data:_url,isProcess:false})
    } catch (e) {
      this.setState({isProcess:false})
    }
  }

  uploadBanner = async(imgData,previewURL) =>{
    try {
      let{isProcess} = this.state;
      let {address} = this?.props?.data??{};
      let currentAddress = Utils.viewCreator();

      if(isProcess) return Utils.setToastAlert("Another request is in process");
      if(!currentAddress || address !== currentAddress) return Utils.setToastAlert("Only user can change banner");
      this.setState({isProcess:true})
      const payload = new FormData()
      payload.append('address', address)
      payload.append('img', imgData)
      await api.uploadAuthorBanner(payload);
      this.setState({isProcess:false,data:previewURL})
      Utils.setToastAlert("Banner successfully changed","success");
    } catch (e) {
      // console.log(e);
      // console.log(e.response);
      this.setState({isProcess:false});
      Utils.setToastAlert("Failed to upload banner","error")
    }
  }

  render(){
    let{data,isProcess} = this.state;
    let {address} = this.props?.data??{};

    let isOwner = address === Utils.viewCreator();
    return(
      <Fragment>
        <BannerViewer handler={this.uploadBanner} data={{data,isOwner,isProcess}}/>
      </Fragment>
    )
  }
}
export default ContentCover;
