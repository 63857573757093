import React, {Component } from 'react'
import {Utils} from "../"

import {ReactComponent as SuccessIcon} from '../../images/icons/circle-check.svg';
import {ReactComponent as SkullIcon} from '../../images/icons/skull.svg';
import {ReactComponent as ExclamationIcon} from '../../images/icons/triangle-exclamation.svg';
import {ReactComponent as InfoIcon} from '../../images/icons/circle-info.svg';
import {ReactComponent as CloseIcon} from '../../images/icons/close.svg';

import "./styles.scss";

class Toast extends Component {
  constructor(props) {
    super(props);
    this.unmounted = false;
    this.state = {
      isHiding:false,
    }
  }
  setState(state, callback) {
    if(!this.unmounted){
      super.setState(state, callback);
    }
  }
  componentDidMount() {
    this.unmounted = false
    this.timeout = setTimeout(() => {
      this.handleRemove()
    }, 5000);
  }
  componentWillUnmount() {
    this.unmounted = true;
    clearTimeout(this.timeout);
  }

  handleRemove = () => {
    this.setState({isHiding:true},()=>{
      setTimeout(() => {
        this.props.onClose(this.props.id);
      }, 400);
    });
  };
  render() {
    let{isHiding} = this.state;
    const {id,appearance,content,icon} = this.props;
    return (
      <li className={`toast ${appearance}${!!isHiding?" hide":""}`}>
        <span>{icon}</span>
        <p>{content}</p>
        <button onClick={this.handleRemove}><CloseIcon/></button>
      </li>
    );
  }
}


class ToastAlert extends Component {
  constructor(props) {
    super(props);
    this.unmounted = false;
    this.state = {
      list :[],
      currentId:0,
    }
  }
  setState(state, callback) {
    if(!this.unmounted){
      super.setState(state, callback);
    }
  }
  componentDidMount(){
    this.unmounted = false;
    Utils.getToastAlert((content,appearance)=>{
      this.setToastAlert(content,appearance)
    })
  }
  componentWillUnmount(){
    this.unmounted = true;
    delete window.removeToast;
  }

  setToastAlert = (content="",appearance="warning") => {
    let appearances = ["success","error","warning","info"];
    appearance = appearances.includes(appearance)?appearance:"warning";

    this.setState((prevState) => ({
      list: [...prevState.list, {id:prevState.currentId+1,content,appearance}],
      currentId: prevState.currentId+1
    }));
  }

  removeToast = (id) => {
    this.setState((prevState) => ({
      list: prevState.list.filter((toast) => toast.id !== id),
    }));
  }

  render(){
    let{list} = this.state;
    let icons = {
      success: <SuccessIcon/>,
      error: <SkullIcon/>,
      warning: <ExclamationIcon/>,
      info: <InfoIcon/>
    }
    return(
      <ul>
        {!!list.length && list.map((toast) => (
        <Toast
          key={toast.id}
          id={toast.id}
          appearance={toast.appearance}
          content={toast.content}
          icon={icons[toast.appearance]}
          onClose={() => this.removeToast(toast.id)}
        />
      ))}
      </ul>
    )
  }
}


export default ToastAlert;
