import React from 'react'

import "./styles.scss";


const lazyLoader = () => {
  return (
    <div className="lazyLoad__container">
      <div className="spinner">&nbsp;</div>
    </div>
  );
};


export default lazyLoader;
