import React, {Component} from 'react';
import { Link,useParams } from 'react-router-dom'
// import $ from "jquery";

import api from "../../api"
import {PopUp} from "../../helper"
import {ReactComponent as InviteFriends} from '../../images/invite-a-friend.svg';
import {ReactComponent as Earnings} from '../../images/earnings.svg';
import {ReactComponent as Question} from '../../images/question.svg';

import './style.scss'


class Referral extends Component {

  constructor(props) {
      super(props)

      this.state = {
        isLoading:false,
        address:this.props.address ||"",
        showRules:false,
        username:"",
      }
  }
  componentDidMount = () =>{
    this.checkWallet()
   }
   checkWallet = async ()=>{
     try {
       let{address} = this.state;

       let res = await api.getUser({address,mode:"checkLogin"});
       // console.log(res);
       let{result} = res.data;
       // console.log(result);
       this.setState({username:result.username})
     } catch (e) {
       console.log(e);
     }
   }



  handleLinkCopy = (e) => {
    e.preventDefault();

   // console.log("btn");
   let {username} = this.state;
   username = username || "";
   let ref = username?`https://kraftly.io?r=${username}`:"--"

   var textField = document.createElement('textarea')
   textField.innerText = ref;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

    let _tooltip = e.currentTarget.classList;
    _tooltip.add("active");
    let _tooltipText = e.currentTarget.querySelector('span');
    _tooltipText.innerText = "Copied"


    setTimeout(function() {
      _tooltip.remove("active");
      _tooltipText.innerText = "Copy"
    }, 3000);
 }

    render() {
      let {address,showRules,username} = this.state;

      let ref = username?`https://kraftly.io?r=${username}`:"--"
        return (
          <main className="main">
        		<div className="container">
              <div className="row no-gutters">
                <div className="col-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb__item"><Link to="/home">Home</Link></li>
                    <li className="breadcrumb__item"><Link to={`/author/${address}/profile?tab=created`} >Profile</Link></li>
                    <li className="breadcrumb__item breadcrumb__item--active">Rewards</li>
                  </ul>
                </div>

                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="referral__panel">

                      <div className="referral__guide__panel">
                        <div className="referral__guide">
                          <div className="referral__guide__title">
                            <InviteFriends />
                            <h1>Invite Friends</h1>
                          </div>
                          <p>Invite your friends to register through the referral link and get rewards when they complete mint,buy or sell every time.</p>
                        </div>
                        <div className="referral__guide">
                          <div className="referral__guide__title">
                            <Earnings />
                            <h1>Share Together</h1>
                          </div>
                          <p>Every time your friends make a trade or mint, you and your friend will share 2% commission equally.</p>
                        </div>
                      </div>
                      <div className="referral__panel__inner">
                        <h2>Your Referral link -</h2>
                        <div className="referral__panel__input">
                          <p>{ref}</p>
                          <button type="button" onClick={this.handleLinkCopy}>
                            <span>Copy</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18,19H6a3,3,0,0,1-3-3V8A1,1,0,0,0,1,8v8a5,5,0,0,0,5,5H18a1,1,0,0,0,0-2Zm5-9.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L16.06,3H8A3,3,0,0,0,5,6v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V10S23,10,23,9.94ZM17,6.41,19.59,9H18a1,1,0,0,1-1-1ZM21,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V6A1,1,0,0,1,8,5h7V8a3,3,0,0,0,3,3h3Z"/></svg>
                          </button>
                        </div>
                        <p className="referral__rules_tab" onClick={()=>this.setState({showRules:!showRules})}>Rules<Question/></p>
                      </div>

                    </div>
                </div>

                {!!showRules && <PopUp.PopupContainer
                  title = "Referral Detailed Rules"
                  onClose={()=>this.setState({showRules:!showRules})}
                  >
                    <div className="referral__info">
                        <ol>
                          <li>All referral commissions (both those received by inviters and those shared with their invited friends) are calculated weekly and transferred to the respective Kraftly accounts.</li>
                          <li>The referral commissions are 2% of fees generated by invited friends (Invitee) and calculated in KTY.</li>
                          <li>There is no limit to the number of friends a single account can invite.</li>
                          <li>Referral commission distribution is not active yet and total accumulated commission will be destributed in first destribution.</li>

                        </ol>
                        <p>Important Notice:</p>
                         <span>Kraftly may adjust the proportion of referrals at any point and reserves the right to change the terms of the referral program at any time due to changing market conditions, risk of fraud, or any other factors we deem relevant.</span>
                    </div>
                </PopUp.PopupContainer>}

              </div>
        		</div>
        	</main>
        )
    }
}
function ReferralWithProps(props){
    const params = useParams();
    let {address} = params || {};
    return (
      <Referral
          {...props}
          address={address}
      />
    );
}
export default ReferralWithProps
