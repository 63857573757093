import React, { useState, useEffect, useRef,useCallback,useMemo } from "react";
import 'pepjs'

// import {ReactComponent as NextIcon} from '../../images/icons/next.svg';
import {ReactComponent as PreviousIcon} from '../../images/icons/previous.svg';

import "./styles.scss";

const DotsComponent = React.memo(({activeIndex=0,count=0,onClick=()=>{}})=>{
  const _index = count > 0 ? activeIndex % count : 0;
  const handleClick = useMemo(() => {
    return (index) => onClick(index);
  }, [onClick]);

  return(<div className="cardslidercontainer__dots">
    {new Array(count).fill(undefined).map((_, index) => {
      return(<button
      key={index}
      className={`dot ${_index === index ? "active" : ""}`}
      onClick={() => handleClick(index)}
      ></button>)
    })}
  </div>)
},
(prevProps, nextProps) => {
   return (
     prevProps.activeIndex === nextProps.activeIndex &&
     prevProps.count === nextProps.count &&
     prevProps.onClick === nextProps.onClick
    );
})


const Carousel = React.memo(({
  children,
  mouseDrag = true,
  touchDrag = true,
  dots = true,
  autoplay = true,
  autoplayHoverPause = true,
  autoplayTimeout = 3000,
  smartSpeed = 500,
  responsive = {},
}) => {

  let totalItems = React.Children.count(children);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [settings, setSettings] = useState({items:1,margin:0});
  const trackRef = useRef(null);
  const autoplayRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const moveX = useRef(0);


  const responsiveSettings = () => {
    let width = window.innerWidth;
    let matchedBreakpoint = Object.keys(responsive).sort((a, b) => b - a).find((bp) => width >= bp);
    let {items,margin} = responsive[matchedBreakpoint]??{};
    items = items || 1;
    margin = margin || 0;
    setSettings((prev)=>({...prev,items,margin}));
    setCurrentIndex((prevIndex) => (prevIndex >= totalItems ? totalItems-1 : prevIndex));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex > 0?prevIndex - 1:totalItems);
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex >= totalItems ? 0 : prevIndex+1;
    });
  };

  const applyAutoplay = () => {
    isDragging.current = false;
    if (autoplay) {
      autoplayRef.current = setInterval(() => {
        handleNext();
      }, autoplayTimeout);
    }
  };

  const stopAutoplay = () => {
    if (autoplayHoverPause && autoplayRef.current) {
      clearInterval(autoplayRef.current);
    }
  };


  useEffect(() => {
    setCurrentIndex(0);
    responsiveSettings();
  }, [children]);


  useEffect(()=>{
    let resizeTimer;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(responsiveSettings, 200); // Trigger after 200ms of no resize activity
   };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimer);
    };
  },[]);

  useEffect(() => {
    !!autoplay?applyAutoplay():clearInterval(autoplayRef.current);
    return () => clearInterval(autoplayRef.current);
  }, [autoplay]);


  const handleDragStart = (e) => {
    e?.preventDefault();
    if (!mouseDrag) return;
    isDragging.current = true;
    let clientX = e?.clientX??0;
    startX.current = clientX;
  };

  const handleDragMove = (e) => {
    e?.preventDefault();
    if(!isDragging.current) return;
    let clientX = e?.clientX??0;
    moveX.current = clientX - startX.current;
  };

  const handleDragEnd = (e) => {
    e?.preventDefault();
    if (!isDragging.current) return;
    isDragging.current = false;

    let _items = settings.items;
    let _w = trackRef.current?.getBoundingClientRect().width || 0;
    let _slide = moveX.current*_items/_w;

    if (moveX.current < -50) handleNext();
    else if (moveX.current > 50) handlePrev();
    startX.current = 0;
    moveX.current = 0;

    // setCurrentIndex(currentIndex)
  };


  const renderChildren = useMemo(() => {
    let{items} = settings;
    let _children = React.Children.toArray(children);
    let _required = Math.max(2*items, totalItems+items);
    return new Array(_required).fill(undefined).map((_, index) => {
      return(<div className="cardslidercontainer__item" key={index}>
        {_children[index%totalItems]}
      </div>)
    })
  },[children,settings.items]);

  return (
    <div className="cardslidercontainer"
      touch-action={!!isDragging.current?"none":"auto"}
      onPointerEnter={stopAutoplay}
      onPointerLeave={applyAutoplay}

      onPointerDown={handleDragStart}
      onPointerMove={handleDragMove}
      onPointerUp={handleDragEnd}

    >
      <div
        className="cardslidercontainer__track"
        ref={trackRef}

        style={{
          "--current-index": currentIndex,
          "--visible-items": settings.items,
          "--smart-speed": `${!!currentIndex?smartSpeed:0}ms`,
          "--card-margin": `${settings.margin}px`,
          "--card-width": `calc(${100/settings.items}% - ${(settings.items-1)*settings.margin/settings.items}px)`,
        }}
      >
        {renderChildren}
      </div>
      <button className="cardslidercontainer__button prev" onClick={handlePrev}><PreviousIcon/></button>
      <button className="cardslidercontainer__button next" onClick={handleNext}><PreviousIcon/></button>
      {!!dots && (<DotsComponent count={totalItems} activeIndex={currentIndex} onClick={setCurrentIndex}/>)}
    </div>
  );
});

export default Carousel;
