import React, { Component,useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {Utils} from "../../../helper"
import {BannerViewer,DpViewer} from "../../../helper/file-viewer.jsx";

import {ReactComponent as Verified} from '../../../images/verified_star.svg';

import "./styles.scss";
const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``


const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?" full__btn":props.largeView?" large__btn":""}`,
  type:"button"
}))``

const AddressView = (props) =>{
  let btnRef = useRef();
  const handleLinkCopy = (e) =>{
    e?.preventDefault();
   let textField = document.createElement('textarea')
   textField.innerText = props.address;
   document.body.appendChild(textField)
   textField.select()
   document.execCommand('copy')
   textField.remove();

   btnRef.current.style.display = "block";
   btnRef.current.children[0].innerText  = "copied"
   /* Alert the copied text */
   setTimeout(function() {
     btnRef.current.style.display = "none"
   }, 3000);
  }

  return (
    <div className="viewable__address" >
      {Utils.shortAddress(props.address)}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" onClick={handleLinkCopy}><path d="M18,19H6a3,3,0,0,1-3-3V8A1,1,0,0,0,1,8v8a5,5,0,0,0,5,5H18a1,1,0,0,0,0-2Zm5-9.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.09,0L16.06,3H8A3,3,0,0,0,5,6v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V10S23,10,23,9.94ZM17,6.41,19.59,9H18a1,1,0,0,1-1-1ZM21,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V6A1,1,0,0,1,8,5h7V8a3,3,0,0,0,3,3h3Z"/></svg>
        <div className="tooltip" ref={btnRef}>
          <p className="tooltip__text">Copy</p>
        </div>
    </div>
  )
}

class TRC721TokenWidget extends Component{
  constructor(props) {
      super(props)

      this.state = {
        preview:false,
        isProcess:false,
        fontSize:"",
      }
      this.myRef = React.createRef();
  }

componentDidMount (){
  this.setupOnLoad()
}

  setupOnLoad = () =>{
    let {isMobile} = this?.props?.layout??{};
    let{symbol,name} = this?.props?.data??{};
    if(!name) return;
    let _width = this.myRef.current.offsetWidth;

    // Adjust name font
    let namContext = document.createElement('canvas').getContext('2d');
    namContext.font = "700 20px Inter";
    let fontSize = Math.floor(_width*20/namContext.measureText(`${name} (${symbol})`).width);
    let fontLimit = !isMobile?20:16;
    let fontLower = !isMobile?14:12;

    fontSize = fontSize > fontLimit?fontLimit:fontSize;
    fontSize = fontSize > fontLower?fontSize:fontLower;
    this.setState({fontSize})
  }

  deployMarketPlace = async() =>{
    try {
      let{isProcess} = this.state;
      let {nftAddress} = this.props.data;

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let instance = await window.tronWeb.contract().at(Utils.MarketInregrator);
      // true if royalable
      await instance.enableMarket(nftAddress,true).send({
       feeLimit:3000 * 1e6,
     });

     this.setState({isProcess:false,preview:false})
     Utils.setToastAlert("Transaction submitted for deploying market","success")

    } catch (e) {
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to deploy marketplace","error")
      }
    }
  }

  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" contract__card__slider":"";

    let{preview,isProcess,fontSize} = this.state;
    let {name,symbol,royaltyAmount,royalityReceiver,nftAddress,account,market,nfts,collections} = this.props.data;
    // console.log(this.props.data);
    let isDeployer = Utils.viewCreator() === account;

    return(
      <div className={`contract__card${isSlider}`}>
        <div className="contract__card__inner">
          <div className="contract__card__profile">

            <Link className="contract__card__logo" to={`/mintable/trc721/${nftAddress}`}>
              <DpViewer address={nftAddress} data={{type:"trc721"}} key={nftAddress}/>
            </Link>

            <div className="contract__card__profile_detail" ref={this.myRef}>
              <Link className="contract__card__name" to={`/mintable/trc721/${nftAddress}`} style={{fontSize}}>
                {name} ({symbol})
              </Link>
              <div className="contract__card__info">
                <div className="contract__card__info_each">
                  <p>Items</p>
                  <span>{Utils.preety(nfts,0)}</span>
                </div>
                <div className="contract__card__info_each">
                  <p>Collections</p>
                  <span>{Utils.preety(collections,0)}</span>
                </div>
              </div>
            </div>


          </div>

          <div className="contract__card__Meta">
            <div className="contract__card__Meta_each"><p>Contract</p> : <AddressView address={nftAddress}/></div>
            {!!isDeployer && <div className="contract__card__Meta_each"><p>Deployer</p> : <AddressView address={account}/></div>}
            <div className="contract__card__Meta_each"><p>Royality</p> : <span>{royaltyAmount/100 || 0}%</span></div>
            {!!isDeployer && <div className="contract__card__Meta_each"><p>Receiver</p> : <AddressView address={royalityReceiver}/></div>}
            {!!market && <div className="contract__card__Meta_each"><p>Market</p> : <AddressView address={market}/></div>}
            {!market && !isDeployer && <div className="contract__card__Meta_each"><p>Market</p> : <span>Not Enabled</span></div>}

          </div>
          {!market && !!isDeployer && <div className="btn__panel">

          {!preview && <CreateBtn onClick={()=>this.setState({preview:true})} fullView>Deploy MarketPlace</CreateBtn>}

          {!!preview && !isProcess && <BackBtn
            largeView
            onClick={()=>this.setState({preview:false})}
            disabled={!!isProcess}
            >
            Cancel
          </BackBtn>}
        {!!preview && <CreateBtn
          largeView={!isProcess}
          fullView={!!isProcess}
          onClick={this.deployMarketPlace}>
          Continue

          {!!isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </CreateBtn>}
      </div>}
        </div>
      </div>
    )
  }
}



class TRC721TokenWidgetDummy extends Component{
  constructor(props) {
      super(props)

      this.state = {
      }
  }


  render(){
    let {isSlider} = this?.props?.layout??{};
    isSlider = !!isSlider?" contract__card__slider":"";

    return(
      <div className={`contract__card${isSlider} dummy_card`}>
        <div className="contract__card__inner">
          <div className="contract__card__profile">

            <div className="contract__card__logo">&nbsp;</div>

            <div className="contract__card__profile_detail">
              <div className="dummy_card_name">&nbsp;</div>
              <div className="contract__card__info">
                <div className="contract__card__info_each">
                  <p>&nbsp;</p>
                  <span>&nbsp;</span>
                </div>
                <div className="contract__card__info_each">
                  <p>&nbsp;</p>
                  <span>&nbsp;</span>
                </div>
              </div>
            </div>


          </div>

          <div className="contract__card__Meta">
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>
            <div className="contract__card__Meta_each"><p>&nbsp;</p> : <span>&nbsp;</span></div>

          </div>

        </div>
      </div>
    )
  }
}






export {
  TRC721TokenWidget,
  TRC721TokenWidgetDummy,
}
