import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components'
// import api from "../../api"
import {Utils} from "../../helper"
// import tronWeb from "../../tronweb"

import UserBody from "../../components/profile-body/user/index.jsx";
import Collection from "../../components/collection/index.jsx";
import OnSale from "../../components/onSale/index.jsx";

const NavBtn = styled.button.attrs(props=>({
  className: props.active?"active":""
}))``


class Author extends Component {
  constructor(props) {
      super(props)
      const urlParams = new URLSearchParams(window.location.search);
      let tab = urlParams.get('tab');
      tab = tab && tab === "onsale"?"onsale":"collection";
      this.state = {
        tab,
        address:this?.props?.address??"",
        myAddress:"",
        user:{},
      }
  }
  componentDidMount (){
    document.title = "Author || Kraftly";

    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({myAddress:address})
    })
  }
  componentDidUpdate(prevProps) {
   if(prevProps.address !== this.props.address){
     this.setState({address:this.props.address})
   }
   const urlParams = new URLSearchParams(window.location.search);
   let tab = urlParams.get('tab')
   if(tab && tab !== this.state.tab){
     this.setState({tab})
   }
 }
 componentWillUnmount(){
   document.title = "Kraftly";
 }

  handleNavBtn = (tab) =>{
    if (window.history.pushState) {
      window.history.pushState(null, null,`${window.location.pathname}?tab=${tab}`);
    }
    this.setState({tab})
  }


  handleAuthor = (user) =>{
    console.log(user);
    this.setState({user})
  }

    render() {
      let {tab,address,myAddress,user} = this.state
        return (
          <UserBody data={{address,myAddress}} handleAuthor={this.handleAuthor}>
            <div className="profile">
  						<ul className="nav nav-tabs profile__tabs" id="profile__tabs" role="tablist">
  							<li>
  								<NavBtn
                    active={tab === "onsale"}
                    onClick={()=>this.handleNavBtn("onsale")}>
                    On Sale
                  </NavBtn>
  							</li>

  							<li>
  								<NavBtn
                    active={tab === "collection"}
                    onClick={()=>this.handleNavBtn("collection")}>
                    Collection
                  </NavBtn>
  							</li>
                <li>
  								<NavBtn
                    onClick={()=>window.location.href = `/author/${address}/profile?tab=onsale`}>
                    Profile
                  </NavBtn>
  							</li>
  						</ul>
  					</div>
            <div className="tab-content">
              {tab === "onsale" && <OnSale data={{address,user}}/>}
              {tab === "collection" && <Collection data={{address,user}}/>}
            </div>
          </UserBody>
        )
    }
}

function AuthorWithProps(props){
    const params = useParams();
    let {address} = params || {};
    return (
      <Author
          {...props}
          address={address}
      />
    );
}

export default AuthorWithProps
