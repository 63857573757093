import React, { Component } from 'react';
import { useParams } from 'react-router-dom';

import api from "../../api"
import {Utils,SearchNotFound} from "../../helper";
import Data from "./data.js"
import tronWeb from "../../tronweb"

import NftCard,{NftCardDummy} from "../../components/nftCard/index.jsx"
import {Paginator,MenuSelect2,GridToggler2} from "../../frag"
import CollectionSummery from '../../reuseable/summery/';

import {ReactComponent as ArrowLeft} from '../../images/arrow-left.svg';
import {ReactComponent as FilterIcon} from '../../images/filter.svg';
import {ReactComponent as CloseIcon} from '../../images/close.svg';




class Explore extends Component {

  constructor(props) {
      super(props)
      let limit = 12;
      const urlParams = new URLSearchParams(window.location.search);
      let page = urlParams.get('page');
      page = Utils.floor(page)
      page = page && page > 0?page:1;
      let start = page > 4?page -3:1;

      this.state = {
        filterString:"",
        address:"",
        isLoading:false,
        nfts:[],
        page,
        start,
        limit,
        count:0,
        type:"gallery",
        arrange:"auto",
        nftAddress:Data.getCollectionAddress(this.props.collection),
        items:0,
        floorPrice:0,
        volume:0,
        transaction:0,

        showFilter:window.innerWidth > 768,
        isGrid:localStorage.getItem('isGrid') === "true",
      }
      this.myRef = React.createRef();
  }
  componentDidMount = () =>{
    document.title =`${Data.getBaseName(this.state.nftAddress)} || Kraftly`;
    this.fetchNfts()
    this.get3pState()
    Utils.getCreator((err,account)=>{
      if(err) return console.log(err);;
      // console.log(account);
      const {address} = account
      this.setState({address})
    })
  }
  componentWillUnmount(){
    document.title = "Kraftly";
  }

  get3pState = async() =>{
    try {
      let{nftAddress} = this.state;

    let result = await api.get3pState({nftAddress});
    // console.log(data);
    let{floorPrice,volume,transaction,items} = result.data.result[0];
    this.setState({floorPrice,volume,transaction,items})
    } catch (e) {
      console.log(e.response);
    }
  }

 UNSAFE_componentWillReceiveProps(nextProps) {
   let{page,nftAddress} = this.state;
     const urlParams = new URLSearchParams(window.location.search);
     let _page = urlParams.get('page');
     _page = Utils.floor(_page) || 1;

     let _collectionId = Data.getCollectionAddress(nextProps.collection);

     if(_page !== page || nftAddress !== _collectionId){
       page = _page && _page > 0?_page:1;
       let start = page > 4?page -3:1;
       // console.log(_page);
       this.setState({nftAddress:_collectionId,page:_page,start},()=>{
         this.fetchNfts()
         this.get3pState()
       })
     }
    }

    fetchNftOwners = async() => {
      try {
        let {nfts,nftAddress} = this.state;
        let tokenIds = nfts.map((j,i)=> j.tokenId);

        let instance = await tronWeb.contract().at(Utils.ProxyContract);
        let owners = await instance.getNftOwners(tokenIds,nftAddress).call();
        owners = owners.map((j,i)=> tronWeb.address.fromHex(j));
        // console.log(owners);
        nfts = nfts.map((item,i)=>{
          item.owner = !!item.owner?item.owner:owners[i];
          // console.log(item);
          return item;
        })
        this.setState({nfts})
      } catch (e) {
        console.log(e);
      }
    }
  updatePagingUrl = () =>{
    let{page} = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set("page", page)
    let params = urlParams.toString();
    if (window.history.pushState) {
      let url = `${window.location.pathname}?${params}`;
      window.history.pushState(null, null,url);
    }
  }
  fetchNfts = async () =>{
    let{page,limit,type,arrange,filterString,nftAddress} = this.state;
      try {
        this.setState({isLoading:true})
        let payload ={mode:"explore",page,limit,type,arrange,nftAddress}
        if(filterString){
          payload.filterString = filterString;
        }

        let res = await api.get3pMarket(payload);
        let{result,count} = res.data;
        count = count || 0;
        this.setState({nfts:result,count,isLoading:false},()=>{
          this.updatePagingUrl()
          this.fetchNftOwners()
        })
      } catch (e) {
        let msg = e.response?.data?.message??"No NFT found";
        Utils.setToastAlert(msg,"error");
        // console.log(e.response);
        this.setState({isLoading:false})
      }
  }
  fetchFilterData = async(event) =>{
    event.preventDefault()

      let{isLoading} = this.state;
      if(isLoading) return Utils.setToastAlert("Previous request processing","info");

      this.setState({nfts:[],type:"gallery",arrange:"auto",page:1,start:1},()=>{
        this.fetchNfts()
      })
  }
  renderNft = () =>{
    let{nfts,nftAddress,isGrid,showFilter} = this.state;
    let layout={
      isMobile:!!isGrid,
      isSmallScale:showFilter,
      isAutoHeight:false,
    }

    return nfts.map((data,i)=>{
      data.nftAddress = nftAddress;
      data.is3rdParty = true;

      if( !!isGrid){
        return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={data.tokenId+nftAddress+"largeA"}>
          <NftCard data={data} layout={layout} key={data.tokenId+nftAddress+"largeB"}/>
        </div>
      }

      return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={data.tokenId+nftAddress+"smallA"}>
        <NftCard data={data} layout={layout} key={data.tokenId+nftAddress+"smallB"}/>
      </div>
    })
  }
  renderDummyNft = () =>{
    let{isGrid,showFilter} = this.state;
    let layout={
      isMobile:!!isGrid,
    }

    let _w = window.innerWidth;
    let count = _w< 576?4:_w< 768?8:_w< 992?!showFilter?6:4:_w< 1200?!showFilter?12:9:12;
    if(isGrid){
      count = _w< 576?8:_w< 768?12:_w< 992?!showFilter?12:9:_w< 1200?12:18;
    }

      return new Array(count).fill(undefined).map((item,i)=>{

        if(!!isGrid){
          return <div className="col-6 col-sm-4 col-lg-3 col-xl-2" key={"smallAD"+i}>
            <NftCardDummy layout={layout} key={"smallB"+i}/>
          </div>
        }

        return <div className="col-12 col-sm-6 col-lg-4 col-xl-3" key={"largeAD"+i}>
          <NftCardDummy layout={layout} key={"largeB"+i}/>
        </div>
      })
  }


  handleInput = e =>{
    let val = e.target.value;
    if (!val || val.length <= 40) {
      this.setState({filterString:val})
    }
  }
  handlePaginator = (value) =>{
    this.setState(value,this.fetchNfts)
    this.myRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  onOption = (name,value)=>{
    let{isLoading} = this.state
    if(isLoading) return Utils.setToastAlert("Previous request processing","info");
    if(this.state[name] === value) return console.log("same option");
    this.setState({[name]:value,nfts:[],page:1},this.fetchNfts);

  }


  selectCollection = (value)=>{
    if (window.history.pushState) {
      let collection = Data.getCollectiontag(value);
      window.history.pushState(null, null,`/explore/${collection}`);
    }
    this.setState({nftAddress:value,nfts:[],page:1},()=>{
      this.fetchNfts()
      this.get3pState()
    })
    document.title =`${Data.getBaseName(value)} || Kraftly`;
  }

    render() {
      let{type,arrange,nfts,filterString,isLoading,count,start,limit,page,nftAddress,items,floorPrice,volume,transaction,isGrid,showFilter} = this.state;
      let typeOptions = {gallery:"Gallery",onAuction:"On Auction",now:"Buy Now",hasOffers:"Has Offers"};
      let priceOptions = {auto:"Price",low:"Low to high",high:"High to low"};
      let collectionOptions = {
        "TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ":"TPUNKS",
        "TFHAtssZyQanmBbGHPvBRdjQy9rmLnaWj2":"TMEEBITS",
        "TYhmAsfWdoTd1mPpcDjBYVKibBRUrJ9CMg":"TronBull",
        "TWi53fvZgTsW8tvAQmYVeThnBeyJqEfJhQ":"BAYCT",
        "TPdiyKSHfz4kzFcCx238b6KaeS3advLEWm":"tapes",
        "TAf4C5L5hXDh4o3ZufeUni4LEQbH1LHrz7":"Cool Cats",
      }

        return (
          <main className="main">
        		<div className="container">
        			<div className="row no-gutters">
                <div className="col-12">
                  <div className="section__title section__title__middle">
                    Explore {Data.getBaseName(nftAddress)}s collection
                  </div>
                </div>
                <div className="col-12" ref={this.myRef}>
                  <CollectionSummery data={{count:items,floorPrice,volume,transaction}}/>
                </div>
        				<div className="col-12" >
        					<div className="ik__home__filter">
        						<form onSubmit={this.fetchFilterData} className="main__filter-search">
        							<input
                        type="text"
                        placeholder="Search token id"
                        value = {filterString}
                        onChange={(e)=>this.setState({filterString:e.target.value})}
                        />
        							<button
                        type="button"
                        onClick={this.fetchFilterData}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                      </button>
        						</form>
                    <button className="explore__filter__btn" onClick={()=>this.setState({showFilter:!showFilter})}>
                      {!showFilter && <FilterIcon/>}

                      {!!showFilter && <ArrowLeft/>}
                      <span>Filters</span>
                    </button>
                    <GridToggler2
                      data={{isGrid}}
                      handleGrid={(e)=>this.setState(e)}
                      />

        					</div>
        				</div>

                {!!showFilter && <div className="col-12 col-md-3">
                  <div className="side__filter">
                    <div className="explore-filter-header">
                      <p onClick={()=>this.setState({showFilter:false})}><CloseIcon/></p>
                    </div>
                    <MenuSelect2 options={typeOptions} value={type} onOption={(e)=>this.onOption("type",e)}/>
                    <MenuSelect2 options={priceOptions} value={arrange} onOption={(e)=>this.onOption("arrange",e)}/>
                    <MenuSelect2 options={collectionOptions} value={nftAddress} isOpen="true" onOption={(e)=>this.selectCollection(e)}/>
                </div>
                </div>}

                <div className={`col-12 ${!!showFilter?"col-md-9":"col-md-12"}`}>
                  <div className="row no-gutters">
                    {!isLoading && !!nfts.length && this.renderNft()}
                    {!!isLoading  && this.renderDummyNft()}
                    {!isLoading && !nfts.length && <div className="col-12"><SearchNotFound data={{msg:"Match Not Found",code:"NFT"}}/></div>}
                  </div>
                </div>

                <div className="col-12">
                  <Paginator  data={{count,start,limit,page,size:nfts.length}} handler={this.handlePaginator}/>
                </div>

        			</div>


        		</div>
        	</main>
        )
    }
}

function ExploreWithProps(props){
    const params = useParams();
    let {collection} = params || {};
    return (
      <Explore
          {...props}
          collection={collection}
      />
    );
}

export default ExploreWithProps
