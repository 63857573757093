import React from 'react'

import "./styles.scss";

const dots = (props) =>{
  return(
    <div className="btn__loader__dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
  )
}

export default dots;
