import {TronWeb} from 'tronweb';

const tronWeb = new TronWeb({
  fullHost: 'https://api.trongrid.io',
  eventServer: 'https://api.trongrid.io',
  privateKey :"091016ACDD528B823D8500EED25FA5E4D06F66F18B71B02B91C00469EAD58893",
  headers: { "TRON-PRO-API-KEY": "0afa8102-70e2-4e72-b541-75c7c1b2f61f" },
})

export default tronWeb;
// console.log(tronWeb.trx.verifyMessageV2());
