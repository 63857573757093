
import React, { Component ,Fragment} from 'react'
import axios from 'axios'
import {Utils} from "../../../../helper"
import api from "../../../../api"

import DotsLoader from "../../../../reuseable/loader/dots"
import {ReactComponent as ChooseIcon} from '../../../../images/icons/choose.svg';
import {ReactComponent as CloseIcon} from '../../../../images/icons/close.svg';
import {ReactComponent as CheckIcon} from '../../../../images/icons/check.svg';

import "./styles.scss";

class DpViewer extends Component{
  constructor(props) {
    super(props)
    this.state = {
      imgData:null,
      previewURL:null,
    }
  }
  componentDidMount (){
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setState(state,callback){
    if(!!this.mounted){
      super.setState(state,callback);
    }
  }
  handleFile = async(event) =>{
    try {
      let{isProcess} = this?.props?.data?.collection??{};
      if(isProcess) return Utils.setToastAlert("Another request is in process");
      let file = event.target.files[0];
      // console.log(file);
      if(!file || !file.type){
        return Utils.setToastAlert("File not selected");
      }

      const allowedTypes = [
        'image/jpeg',  // JPEG
        'image/png',   // PNG
        'image/gif',   // GIF
        // 'image/bmp',   // BMP
        // 'image/webp',  // WEBP
        // 'image/tiff',  // TIFF
        // 'image/svg+xml' // SVG
      ];
      const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!allowedTypes.includes(file.type)) {
        return Utils.setToastAlert(`Invalid file type`);
      }

      if (!allowedExtensions.includes(fileExtension)) {
        return Utils.setToastAlert(`Can not upload .${fileExtension}, Only support .${fileExtension.join(" .")} files type`);
      }

      if (file && file.size > 5*1024*1024) { //5MB
        return Utils.setToastAlert("File size exceeds 5MB. Please upload a smaller file.B");
      }

      file = new File([file], `image_${Date.now()}.${fileExtension}`, {type: file.type});
      let previewURL = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });

      this.setState({imgData:file,previewURL});
    } catch (e) {
      Utils.setToastAlert("Unexpexted error occured");
    }
  }

  uploadFile = async() =>{
    let {isProcess} = this.props.data;
    let{imgData,previewURL} = this.state;
    if(!!isProcess) return;
    await this.props.handler(imgData,previewURL);
    this.setState({imgData:null,previewURL:null})
  }
  cancelUpload = () =>{
    let {isProcess} = this.props.data;
    let{imgData} = this.state;
    if(!!isProcess) return;
    this.setState({imgData:null,previewURL:null})
  }
  render(){
    let{imgData,previewURL} = this.state;
    let {data,isOwner,isProcess} = this?.props?.data??{};
    let imgSource = !!imgData?previewURL:!!data?data:Utils.getDp();
// imgData = true;
// isProcess = true;
    return(
      <div className="content__avatar">
        <div className="content__avatar__body">
          <img src={imgSource} alt="Profile"/>
          {!!isOwner && <div className="content__avatar__owner">
            {!imgData && !isProcess && <label htmlFor="content-avatar"><ChooseIcon/></label>}
            {!imgData && !isProcess && <input id="content-avatar" type="file" accept="image/*" onChange={this.handleFile}/>}

            {!!imgData && !isProcess && <button onClick={this.cancelUpload}><CloseIcon/></button>}
            {!!imgData && !isProcess && <button onClick={this.uploadFile}><CheckIcon/></button>}
            {!!imgData && !!isProcess && <DotsLoader/>}
          </div>}
        </div>
      </div>
    )
  }
}

class ContentDp extends Component{
  constructor(props) {
    super(props)
    this.state = {
      data:null,
      isProcess:false,
    }
  }
  componentDidMount (){
    this.mounted = true;
    this.fetchFile();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setState(state,callback){
    if (this.mounted){
      super.setState(state,callback);
    }
  }

  fetchFile = async() =>{
    try {
      let {address,files} = this?.props?.data??{};
      files = files??{};

      if(!address && !files.dp) return "";
      let url = Utils.getDp(address,files?.dp??"");
      this.setState({isProcess:true})
      let _fileData = await api.fileApi.get(url);
      let _url = URL.createObjectURL(_fileData?.data??"");
      this.setState({data:_url,isProcess:false})
    } catch (e) {
      this.setState({isProcess:false})
    }
  }

  uploadDp = async(imgData,previewURL) =>{
    try {
      let{isProcess} = this.state;
      let {address,files} = this?.props?.data??{};
      let currentAddress = Utils.viewCreator();

      if(isProcess) return Utils.setToastAlert("Another request is in process");
      if(!currentAddress || address !== currentAddress) return Utils.setToastAlert("Only user can change Profile picture");
      this.setState({isProcess:true})
      const payload = new FormData()
      payload.append('address', address)
      payload.append('img', imgData)
      await api.uploadAuthorDp(payload);
      this.setState({isProcess:false,data:previewURL})
      Utils.setToastAlert("Profile picture successfully changed","success");
    } catch (e) {
      console.log(e);
      // console.log(e.response);
      this.setState({isProcess:false});
      let msg = e?.response?.data?.message??"Failed to upload profile picture";
      Utils.setToastAlert(msg,"error");
    }
  }

  render(){
    let{data,isProcess} = this.state;
    let {address} = this.props?.data??{};

    let isOwner = address === Utils.viewCreator();
    return(
      <Fragment>
        <DpViewer handler={this.uploadDp} data={{data,isOwner,isProcess}}/>
      </Fragment>
    )
  }
}
export default ContentDp;
