import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import App from './app/index.js';

import reportWebVitals from './reportWebVitals';
import './scss/custom.scss';
import './scss/chrismas.scss';



import {NoLogin,ToastAlert} from "./helper"
// require('default-passive-events');

const noLogin = createRoot(document.getElementById('nologin-popup'));
noLogin.render(<NoLogin/>);

const toast = createRoot(document.getElementById('toast-alert'));
toast.render(<ToastAlert/>);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// ReactDOM.render(<Loader />,document.getElementById('home-loader'));
// ReactDOM.render(<React.StrictMode><App /></React.StrictMode>,document.getElementById('root'));
// ReactDOM.render(<NoLogin />,document.getElementById('nologin-popup'));
// ReactDOM.render(<ToastAlert />, document.getElementById('toast-alert'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
