import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'

import {Form,Menu,Profile} from '../parts'
import tronWeb from "../../tronweb"
import {Utils} from "../../helper"

import './styles.scss';


const Logo = () => {
  return (
    <div className="header__logo">
      <Link to="/">
        <img src="/asset/img/logo.png" alt="logo" />
      </Link>
    </div>
  );
};

const Header = () =>{

  const [menuActive, setMenuActive] = useState(false);

  const checkTrxPrice = async()=>{
    try {
      let _event = await tronWeb.getEventResult("TQn9Y2khEsLJW1ChVWFMSMeRDow5KcbLSE",{eventName:"Snapshot",size:1});
      _event = _event?.data??[];
      if(!_event || !_event.length) return;

      let {token_balance,trx_balance} = _event[0].result;
      trx_balance = parseInt(trx_balance);
      token_balance = parseInt(token_balance);

       let usdtPrice = (token_balance * 1e6)/(trx_balance + 1e6);
       usdtPrice = Math.ceil(usdtPrice)/1e6;
       Utils.setUsdtRate(usdtPrice)
    } catch (e) {
      // console.log(e);
    }
  }

  useEffect(() => {
    checkTrxPrice();
  }, []);

  return(
    <header className="header">
      <div className="header__content">
          <Logo />
          <Form />
          <Menu isActive={!!menuActive}/>
          <Profile />

        <button className={`header__btn${!!menuActive?" active":""}`} onClick={()=>setMenuActive(!menuActive)}>
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  )
}


export default Header
