import React, {Component} from 'react'
import api from "../../api"

import Carousel from "../../reuseable/carousel"
import {TRC721TokenWidget,TRC721TokenWidgetDummy} from "../../reuseable/cards/TRC721Contract";


class CarouselTRC721 extends Component{
  constructor(props) {
    super(props)
    this.state = {
      collections:[],
    }
  }
  componentDidMount (){
    this.mounted = true;
    this.fetchCollection()
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  setState(state,callback){
    if(!!this.mounted){
      super.setState(state,callback);
    }
  }

  fetchCollection = async () =>{
      try {
        let limit = window.innerWidth < 768?6:12;

        let res = await api.getNftContracts({arrange:"new",limit});
        // console.log(res);
        let{result} = res.data;
        this.setState({collections:result})
      } catch (e) {
        // console.log(e.response);
      }
  }
  renderCollection = () =>{
    let{collections} = this.state;
    let layout = {isSlider:true}
    if(!collections || !collections.length){
      return new Array(4).fill(undefined).map((_,i)=><TRC721TokenWidgetDummy layout={layout} key={i}/>)
    }

    return collections.map((data,i)=>{
      return <TRC721TokenWidget data={data} layout={layout} key={data.nftAddress}/>
    })
  }
  handleTrigger = (val) =>{
    let{uniqueIndex} = this.state;
    if(val === "next"){
      document.querySelector(`.${uniqueIndex} .owl-next`).click();
    }else {
      document.querySelector(`.${uniqueIndex} .owl-prev`).click()
    }
  }

  render(){
    let {uniqueIndex,collections} = this.state
    let res = {
      576 : {items: 1,margin:8},
      768 : {items: 2,margin:10},
      992 : {items: 3,margin:8},
      1200 : {items: 4,margin:10,mouseDrag: false,dots: false},
    }

    return(
      <Carousel
        mouseDrag= {true}
        touchDrag= {true}
        dots= {true}
        autoplay= {true}
        autoplayHoverPause= {true}
        autoplayTimeout= {4000}
        smartSpeed= {1000}
        responsive= {res}
      >
        {this.renderCollection()}
      </Carousel>
    )
  }
}

export default CarouselTRC721
