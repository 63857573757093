import React, { Component,useEffect,Fragment  } from 'react'
import styled from 'styled-components'
import $ from "jquery";
import { Link } from 'react-router-dom'
import {NftViewer} from "../helper/file-viewer.jsx"
import {BannerViewer} from "../helper/file-viewer.jsx"

import api from "../api"
import {Utils,PopUp} from "../helper"

import {ReactComponent as Upload} from '../images/upload.svg';

import {ReactComponent as Publish} from '../images/publish.svg';
import {ReactComponent as JsonIcon} from '../images/json.svg';
import {ReactComponent as Question} from '../images/question.svg';
import {ReactComponent as FilesIconWhite} from './../images/multi-files.svg';
import {ReactComponent as Clipboard} from '../images/clipboard.svg';
import {ReactComponent as Machine} from '../images/machine.svg';



const CreateBtn = styled.button.attrs(props=>({
  className: `sign__btn ${props.fullView?"full__btn":props.largeView?"large__btn":props.smfullView?"sm__full__btn":props.smlargeView?"sm__large__btn":""}`,
  type:"button"
}))``

const BackBtn = styled.button.attrs(props=>({
  className: `sign__btn red__btn ${props.fullView?"full__btn":props.largeView?"large__btn":props.smfullView?"sm__full__btn":props.smlargeView?"sm__large__btn":""}`,
  type:"button"
}))``

const GreenBtn = styled.button.attrs(props=>({
  className: `sign__btn green__btn ${props.fullView?"full__btn":props.largeView?"large__btn":props.smfullView?"sm__full__btn":props.smlargeView?"sm__large__btn":""}`,
  type:"button"
}))``






const beautifyAttr = (str) => {
    try {
      if(!str || !str.length) return false;

      let finalArray = str.map((item,i) => {
      if(!item) return "";
      let [k,v] = Object.entries(item)[0];
      k = k.toString().trim().toLowerCase();
      if(k.length < 3 || k.length > 40) return "";
      v = v.toString().trim().toLowerCase();
      if(v.length < 3 || v.length > 40) return "";
      return {[k]:v}
    }).filter(j =>!!j);

    if(!finalArray.length) return false;

      return finalArray;
    } catch (e) {
      // console.log(e);
        return false;
    }
}

const beautifyContent = (str) => {
    try {
      if(!str || !str.length) return false;

      let finalArray = str.map((item,i) => {
      if(!item) return "";
      let{url,formate,locked} = item;
      url = !!url && !!Utils.validateUrl(url)?url:"";
      if(!url) return ""
      let _types = ["image","video","audio","pdf"];
      formate = !!formate && !!_types.includes(formate)?formate:"image";
      locked = !!locked && locked.toLowerCase() === "true"?true:false;
      return {url,formate,locked}
    }).filter(j =>!!j);

    if(!finalArray.length) return false;

      return finalArray;
    } catch (e) {
      // console.log(e);
        return false;
    }
}



async function fileToJSON(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = event => resolve(event.target.result)
    fileReader.onerror = error => reject(error)
    fileReader.readAsText(file)
  })
}

const SignGroup = (props) =>{
  return <div className={props.small?"col-12 col-sm-6 col-md-12 col-lg-6":"col-12"}>
    <div className="update__details__group">
      {props.children}
    </div>
  </div>
}




const CollectionMain = (props) =>{
  let {tab,isSmall,data,noMenu} = props??{};
  let {address,imgData,isOwner,files} = data??{};

  useEffect (() => {
    if(!!props.tab){
      $(".ik__menu_active")[0].scrollIntoView();
      window.scrollTo({ top: 0});
    }
  });


  return <div className="main">
    {!noMenu && <div className="ik__menu__header">
      <div className="ik__menu__header__body">
        <ul className="ik__menu__header__list">
          <li><Link to="/mintable" className={tab === "home"?"ik__menu_active":""}>Collections</Link></li>
          <li><Link to="/mintable/create" className={tab === "create"?"ik__menu_active":""}>Create Collection</Link></li>
          <li><Link to="/mintable/my-collections" className={tab === "collections"?"ik__menu_active":""}>My Collections</Link></li>

          <li><Link to="/mintable/trc721" className={tab === "trc721"?"ik__menu_active":""}>NFT Contracts</Link></li>
          <li><Link to="/mintable/create-trc721" className={tab === "deploy"?"ik__menu_active":""}>Create TRC721</Link></li>
          <li><Link to="/mintable/my-trc721" className={tab === "myTrc721"?"ik__menu_active":""}>My Contracts</Link></li>

        </ul>
      </div>
    </div>}

    {tab === "home" && <div className="ik__hom__banner" >
      <div className="ik__hom__banner_text">Collections</div>
    </div>}

    {!!address && <BannerViewer data={{address,files,cName:"ik__hom__banner",imgData}}  key={address}>
      {!!isOwner && <div className="upload-banner">
        <label htmlFor="banner-upload">
            <img src={`/asset/img/upload-${!!imgData?"file":"white"}.svg`} alt=""/>
        </label>
        {!imgData && <input id="banner-upload" type="file" accept=".png,.jpg,.jpeg" onChange={props.handleBannerFile}/>}
        {!!imgData && <button id="banner-upload" onClick={props.uploadBanner}></button>}
      </div>}
    </BannerViewer>}

    <div className={`${!!isSmall?"container-fluid":"container"}`}>
      <div className={`row no-gutters`}>
        {props.children}
      </div>
    </div>
 </div>
}


const EachFillOption = (props) =>{
  return <div
    className={`IkCollection__fill__option ${!!props.selected?"selected__fill_option":""}`}
    onClick={props.onClick}
    >
    {props.children}
  </div>
}


class CollectionFillSelect extends React.Component {

  handleSubmit = (val) =>{
    let {selected,address,nfts} = this.props.data;

    if(selected === "manual" && !address) return $(".header__action-btn--signin").click();
    if(selected === "manual" && !nfts.length) return Utils.setToastAlert("Collection not found, Please create collection");

    this.props.handleState({showFillOptions:false})
  }
  render() {
    let {selected,collection} = this.props.data;
    return(
      <div className="IkCollection__fill__options">
        <EachFillOption onClick={()=>this.props.handleState({selected:"generate"})} selected={selected === "generate"}>
          <Machine />
        </EachFillOption>
        <EachFillOption onClick={()=>this.props.handleState({selected:"json"})} selected={selected === "json"}>
          <JsonIcon />
        </EachFillOption>
        <EachFillOption onClick={()=>this.props.handleState({selected:"multifile"})} selected={selected === "multifile"}>
          <FilesIconWhite />
        </EachFillOption>
        {collection.collectionType === 3 && <EachFillOption onClick={()=>this.props.handleState({selected:"upgrade"})} selected={selected === "upgrade"}>
          <Clipboard />
        </EachFillOption>}
        <EachFillOption onClick={()=>this.props.handleState({selected:"publish"})} selected={selected === "publish"}>
          <Publish />
        </EachFillOption>
    </div>
    )
  }
}

class JsonMethod extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      preview:false,
      json:"",
    }
  }

  componentDidMount (){
    let{json_data} = this.props.data;
    if(json_data && !!json_data.length){
      this.setState({json:JSON.stringify(json_data,null,4)})
    }
  }
  handleGenerator = () =>{
    let {json} = this.state;
    let{address,collection,collectionId} = this.props.data;

      if(!json) return Utils.setToastAlert("Invalid JSON provided")
      if(!address) return $(".header__action-btn--signin").click();
      if(!collectionId) return Utils.setToastAlert("Collection does not exists");

      // let regex = /\,(?!\s*?[\{\[\"\'\w])/g;
      let regex = /,(?!\s*?[{["'w])/g;

      json = json.replace(regex, ''); // remove all trailing commas
      json = JSON.parse(json);
      json = json.slice(0, collection.size);


      json = json.map((item,i)=>{
        let {description,name,tags,adult,attributes,file,unlockable,rank,rarity,elink} = item;
        // console.log(description);
        let payload = {
          name:"",
          description:"",
          tags:"",
          attributes:[],
          adult:"false",
          file:"",
        };
        if(!!file){
          payload.file = file;
        }
        if(!!name && name.length >= 3 && name.length <= 30){
          payload.name = name;
        }

        if(!!tags && !!tags.length){

          let regex = /^[a-z]+$/;

          tags = tags.split("#")
          .map((_t,j)=> _t.replace(/\s/g, "").toLowerCase())
          .filter(k => k.length >= 2 && !!regex.test(k))
          .map((_tf,l)=> `#${_tf}`)

          // console.log(tags);
          if(!!tags.length){
            payload.tags = tags.join(',');
          }
        }



        payload.adult = !!adult && adult.toLowerCase() === "true"?"true":"false";

        if(!!description && description.length <= 300){
          payload.description = description;
        }

        if(!!elink && !!Utils.validateUrl(elink)){
          payload.elink = elink.trim();
        }
        if(!!rank && Utils.inputSafe(rank,0)){
          payload.rank = Utils.inputSafe(rank,0);
        }
        if(!!rarity && !!Utils.inputSafe(rarity,2)){
          payload.rarity = Utils.inputSafe(rarity,2);
        }

        attributes = beautifyAttr(attributes);
        if (!!attributes && !!attributes.length) {
          payload.attributes = attributes;
        }

        unlockable = beautifyContent(unlockable);
        if (!!unlockable && !!unlockable.length) {
          payload.unlockable = unlockable;
        }

        return payload;
      })
    this.props.jsonHandler(json)
  }
  handleJsonUpload = async(e) =>{
    try {
      let json = await fileToJSON(e.target.files[0])
      this.setState({json})
      // let regex = /\,(?!\s*?[\{\[\"\'\w])/g;
      let regex = /,(?!\s*?[{["'w])/g;
      json = json.replace(regex, ''); // remove all trailing commas
      json = JSON.parse(json);

    } catch (e) {
      // console.log(e);
      return Utils.setToastAlert(e.toString())
    }
  }
  downloadDummyJson = (e) => {
      e.preventDefault()

      let data = new Array(2).fill(undefined).map((_,i)=>{
        return {
          name :`Crypto Heart #${i+1}`,
          file:`${i+1}.png`,
          description:"After purchasing you will able to receive.",
          tags:!i?"#art,#anime,#memes":"",
          adult:"false",
          rank:i+1,
          rarity:100/(i+1),
          elink:!i?`https://mywebsite.com/${i+1}.html`:"",
          attributes:!i?[{"background":"blue"},{"body":"reflect"},{"hat":"dark"}]:[],
          unlockable:!i?[{url:`https://mywebsite.com/content/${i+1}.html`,formate:"image",locked:true}]:[],
        }
      })
      // Create a blob with the data we want to download as a file
      const blob = new Blob([JSON.stringify(data,null,4)], { type: 'text/json' })
      // Create an anchor element and dispatch a click event on it
      // to trigger a download
      const a = document.createElement('a')
      a.download = "demo.json"
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()
    }


    clearJson = () =>{
      this.setState({json:""})
      this.props.jsonHandler([])
    }

  render() {
    let {preview,json} = this.state;


    return(
      <Fragment>
        {!json && <PopUp.PopupContainer
          onClose={()=>this.props.handleState({selected:"manual"})}
          title="Upload Json File"
          >
        <div className="ik_collection__popup__content">
            <div className="json__file__input">
              <label htmlFor="json-file">
                <Upload />
                Select Json File
              </label>
              <input
                id="json-file"
                type="file"
                accept=".json"
                onChange={this.handleJsonUpload}
                />
            </div>
            <p className="json__info__label" onClick={this.downloadDummyJson}>Json formate <Question/></p>


              <div className="btn__panel">
                {!preview && <BackBtn fullView onClick={()=>this.props.handleState({selected:"manual"})}>Back</BackBtn>}

              </div>
          </div>

        </PopUp.PopupContainer>}

        {!!json && <PopUp.PopupContainer
          className="fullscr__popup"
          onClose={()=>this.props.handleState({selected:"manual"})}
          title="Edit JSON content"

          >
        <div className="ik_collection__popup__content ik_collection__popup__content__full">
          <textarea
            name="json"
            className="json_file_text"
            onChange={(e)=>this.setState({json:e.target.value})}
            value={json}
            readOnly={!!preview}
            ></textarea>

              <div className="btn__panel">
                {!preview && <BackBtn smlargeView onClick={this.clearJson}>Clear</BackBtn>}
                {!preview && <CreateBtn smlargeView onClick={()=>this.setState({preview:true})}>Continue</CreateBtn>}
                {!!preview && <BackBtn smlargeView onClick={()=>this.setState({preview:false})}>Go Back</BackBtn>}
                {!!preview && <CreateBtn smlargeView onClick={this.handleGenerator}>Yes, Continue</CreateBtn>}
              </div>
          </div>

      </PopUp.PopupContainer>}
      </Fragment>



    )
  }
}


class GenerateMethod extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      preview:false,
      description:"",
      name:"",
      tags:'',
      adult:false,
      formate:"jpg"
    }
  }
  handleInput = event =>{
    const name = event.target.name;
    let value = event.target.value;
    if(name === 'description'){
      if(value.length > 300){
        event.target.style.borderColor = "red";
      }else if(event.target.style.borderColor) {
        event.target.style.borderColor = "";
      }
    }
    if(name === 'name'){
      let _name = value.replace(/ /g, "");
      let _tfirst = _name.substr(0,6).toUpperCase();
      let _cfirst = _name.substr(0,7).toUpperCase();
      let _fFirst = _name.substr(0,5).toUpperCase();

      if(
        _tfirst === "ROCKID" || _tfirst === "ROCKLD" ||
        _cfirst === "CLOUDID" || _cfirst === "CLOUDLD" ||
        _fFirst === "FOXID" || _fFirst === "FOXLD"
      ){
        value = "";
      }

        if(value.length < 3 || value.length > 30){
          event.target.style.borderColor = "red";
        }else if(event.target.style.borderColor) {
          event.target.style.borderColor = "";
        }
    }
    this.setState({[name]:value})
  }
  handleTags = event=>{
    // console.log(event.charCode);
    var regex = /^[a-z,#]+$/;
    let value = event.target.value.replace(/\s/g, "").toLowerCase();
    if(value.length > 0 && !regex.test(value))return;

    if(value !== "#" && value.length > 0 && !value.startsWith("#")){
      value = "#"+value;
    }
    let res = value.split(",")

    res = res.filter(i => i.startsWith("#")).toString()
    if(value.length>2 && value.charAt(value.length-1) === ","){
      if(this.state.tags.charAt(this.state.tags.length-1) !== "#"){
        res += ",#";
      }

    }
    this.setState({tags:res})
  }

  handleGenerator = () =>{
    let {description,name,tags,adult,formate} = this.state;
    let{address,collection,collectionId} = this.props.data;

    if(!address) return $(".header__action-btn--signin").click();
    if(!collectionId) return Utils.setToastAlert("Collection does not exists");


    let json = new Array(collection.size).fill(undefined).map((_,i)=>{

      let payload = {
        name: `${name} #${i+1}`,
        description,
        tags,
        adult,
        file: `${i+1}.${formate}`,
        rank:0,
        rarity:0,
        elink:"",
        attributes:[],
        unlockable:[],
      }
      return payload;
    })


    // Create a blob with the data we want to download as a file
      const blob = new Blob([JSON.stringify(json,null,4)], { type: 'text/json' })
      // Create an anchor element and dispatch a click event on it
      // to trigger a download
      const a = document.createElement('a')
      a.download = `${collection.name || "collection"}.json`;
      a.href = window.URL.createObjectURL(blob)
      const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      })
      a.dispatchEvent(clickEvt)
      a.remove()

      this.props.jsonHandler(json)
  }


  render() {
    let {preview,name,description,tags,adult,formate} = this.state;
    return(
        <PopUp.PopupContainer
        className="mblscr__popup"
        onClose={()=>this.props.handleState({selected:"manual"})}
        title="Generate JSON"
        >
      <div className="update__details__form ">

        <div className="row no-gutters">
        <SignGroup>
          <label className="update__details__label" htmlFor="name">Item name</label>
          <input
            id="name"
            type="text"
            name="name"
            className="update__details__input"
            placeholder="Crypto Heart"
            value={name}
            onChange={this.handleInput}
            readOnly={!!preview}
            />

        </SignGroup>

        <SignGroup>
          <label className="update__details__label" htmlFor="description">Description</label>
          <textarea
            id="description"
            name="description"
            className="update__details__textarea"
            placeholder="Same description will be added in all NFTs"
            value={description}
            onChange={this.handleInput}
            readOnly={!!preview}

            />
        </SignGroup>


        <SignGroup>
          <label className="update__details__label" htmlFor="tags">Tags</label>
          <textarea
            id="tags"
            name="tags"
            className="update__details__textarea"
            placeholder="Same tags will be added in all NFTs"
            value={tags}
            onChange={this.handleTags}
            readOnly={!!preview}

            ></textarea>
        </SignGroup>

        <SignGroup >
          <label className="update__details__label" htmlFor="adult">18+ content?</label>
          <select
            id="adult"
            name="adult"
            className="update__details__select"
            onChange={(e)=>this.setState({adult:e.target.value})}
            value={adult}
            disabled={!!preview}

            >
            <option value={"true"}>Yes.</option>
            <option value={"false"}>No!</option>

          </select>
        </SignGroup>
        <SignGroup >
          <label className="update__details__label" htmlFor="file-formate">File Formate?</label>
          <select
            id="file-formate"
            name="file-formate"
            className="update__details__select"
            onChange={(e)=>this.setState({formate:e.target.value})}
            value={formate}
            disabled={!!preview}

            >
            <option value={"jpg"}>jpg</option>
            <option value={"jpeg"}>jpeg</option>
            <option value={"png"}>png</option>
            <option value={"gif"}>gif</option>
            <option value={"mp4"}>mp4</option>
            <option value={"mp3"}>mp3</option>

          </select>
        </SignGroup>
      </div>
          <div className="btn__panel">
            {!preview && <BackBtn largeView onClick={()=>this.props.handleState({selected:"manual"})}>Back</BackBtn>}
            {!preview && <CreateBtn largeView onClick={()=>this.setState({preview:true})}>Continue</CreateBtn>}
            {!!preview && <BackBtn largeView onClick={()=>this.setState({preview:false})}>Go Back</BackBtn>}
            {!!preview && <GreenBtn largeView onClick={this.handleGenerator}>Continue</GreenBtn>}

          </div>

      </div>
        </PopUp.PopupContainer>
    )
  }
}



const MultiFileCard = (props) =>{

  const renderFile = () =>{
    let {imgData} = props.data;
    let ext = imgData.name.split(".");
    ext = ext[ext.length - 1].toLowerCase();
    let _url = URL.createObjectURL(imgData)
    // let ext = "png";
    // let _url = Utils.getDp()
    if(ext === "jpg" || ext === "gif" || ext === "png"){
      return <img src={_url} alt="" />
    }else if(ext === "mp4"){
      return <video src={_url} controls muted autoPlay loop />
    }else if(ext === "mp3"){
      return <audio src={_url} controls muted autoPlay loop />
    }
  }
  return (
    <div className="multi_nft_file_card">
      <div className="multi_nft_file_card__preview">
        {!!props.data.imgData && renderFile()}
      </div>
      <p className="nft_file_title">{props.data.name || "No name"}</p>

    </div>
  )
}

class AttachMultiFile extends Component {

  constructor(props){
    super(props)
    this.state={
      files:[],
    }
  }

  handleFilesSelect = (event) =>{

    let _files = event.target.files;
    if(!_files || !_files[0]){
      return Utils.setToastAlert("File not selected");
    }


    const file_ext = ["jpg","jpeg","gif","mp4","mp3","png"];

    _files = Object.values(_files).map((file, i) => {
      let ext = file.name.split(".");
      let extName = ext[ext.length - 1].toLowerCase();

      if (!file.type || !file_ext.includes(extName)) {
        Utils.setToastAlert(`Can not upload .${ext}, Only support .jpg .png .gif .mp4 .mp3 files type`);
        return false;
      }

      if (file.size > 2*1024*1024) { //2MB
         Utils.setToastAlert("NFT file size to large, try uploading file less than 2MB");
         return false;
      }
      return file;
    }).filter(i => !!i)
    this.setState({files:_files})

  }


  handleAttach = () =>{

    let {files} = this.state;

    if(!files.length) return Utils.setToastAlert("No files selected");

    let {nfts,multi_files} = this.props.data;

    let _nfts = nfts.filter(i => !i.isFilled);
    if(!_nfts.length) return Utils.setToastAlert("No Blank NFT found");

     _nfts = nfts.filter(i => i.file && !i.isFilled);
    if(!_nfts.length) return Utils.setToastAlert("Please attach file name in json file");



    files.forEach((item,i)=>{
      let _index = multi_files.findIndex(j => j.name === item.name);
      if(_index !== -1){
        multi_files[_index] = item;
      }else {
        multi_files.push(item)
      }
    })

    this.props.handleState({multi_files,selected:"manual"})
  }

  clearFiles = () =>{
    this.setState({files:[]},()=>{
      this.props.handleState({multi_files:[]})
    })
  }

  renderFilesPreview = () =>{
    let {files} = this.state;
    let {nfts,multi_files} = this.props.data;

    let _nfts = JSON.parse(JSON.stringify( nfts.filter(i => !i.isFilled)));

    return _nfts.map((item,i)=>{
      let{tokenId,file} = item;

      let imgData = files.find(i => i.name === file) || multi_files.find(i => i.name === file);

      if(!!imgData){
        item.imgData = imgData;
      }
      return <div className="col-6 col-sm-4  col-lg-3 col-xl-2" key={tokenId+"a"+i}>
        <MultiFileCard data={item} key={tokenId+imgData+"a"+i}/>
      </div>
    })
  }
  render() {
    let {nfts} = this.props.data;
    let _nfts = JSON.parse(JSON.stringify( nfts.filter(i => !i.isFilled)));

    return(
      <PopUp.PopupContainer
        className="fullscr__popup"
        onClose={()=>this.props.handleState({selected:"manual"})}
        title="Select files"
        >

        <div className="ik_collection__popup__content ik_collection__popup__content__full">

          <div className="json__file__input json__file__input__mini">
            <label htmlFor="json-file">
              <Upload />
              Select NFT Files
            </label>
            <input
              id="json-file"
              type="file"
              multiple = {true}
              onChange={this.handleFilesSelect}
              />
          </div>


        <div className="btn__panel">
          {<BackBtn smlargeView onClick={this.clearFiles}>Clear</BackBtn>}
          {<CreateBtn smlargeView onClick={this.handleAttach}>Attach Files</CreateBtn>}
        </div>


        {!!_nfts.length && <div className="ik_multi_preview">
          <h4 className="sign__title">Preview blank NFTs</h4>
          <div className="row no-gutters">
             {this.renderFilesPreview()}
          </div>
        </div>}


        </div>



    </PopUp.PopupContainer>
    )
  }
}









class UpgradeCollection extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      fees:25*1e6,
      preview:false,
      isProcess:false,
      size:5,
    }
  }
  componentDidMount (){
    this.getDiscount()
  }

  getDiscount = async() =>{
    try {
      let{collection} = this.props.data;
      if(!collection.author) return console.log("collection does not exists");
      let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
       let fees = await instance.getBookingFee(collection.author,1).call();

      fees = window.tronWeb.toDecimal(fees);
      // console.log(fees);
      this.setState({fees})
    } catch (e) {
        console.log(e);
        this.setState({fees:25*1e6})
    }
  }
  handleInput = e =>{
  let input = Utils.inputNumber(e.target.value,0);
  this.setState({size:input})
  }


  handleGenerateCollection = async()=>{
    try {
      let{size,fees,isProcess} = this.state;
      let{collection,collectionId} = this.props.data;
      let address = Utils.viewCreator();

      if(!address) return $(".header__action-btn--signin").click();
      if(!address || collection.author !== address) return Utils.setToastAlert("Only author can add NFTs");

      if(!size) return Utils.setToastAlert("Collection upgrade size should be atleast 1");

      fees = Utils.ceil(fees*size);

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({isProcess:true})

      let balance = await window.tronWeb.trx.getUnconfirmedBalance(address);
      balance = Utils.floor(balance);

      if(!balance || balance < fees){
        this.setState({isProcess:false})
        return Utils.setToastAlert("Not sufficient TRX balance");
      }

      let gasFees = (20 * 1e6) + fees;

      let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
      await instance.upgradeDynamicCollection(collectionId,size).send({
       feeLimit: gasFees,
       callValue:fees,
     });
     this.setState({isProcess:false,preview:false})

     Utils.setToastAlert("Transaction submitted, New NFTs will arrive after confirmation of transaction","success")

    } catch (e) {
      console.log(e);
      this.setState({isProcess:false})
      if(e === "Confirmation declined by user"){
        Utils.setToastAlert("Confirmation declined","error")
      }else {
        Utils.setToastAlert("Failed to add NFTs in collection","error")
      }
    }
  }

  handlePreview = ()=>{
    try {
      let{size,isProcess} = this.state;
      let{collection} = this.props.data;
      let address = Utils.viewCreator();

      if(!address) return $(".header__action-btn--signin").click();
      if(!address || collection.author !== address) return Utils.setToastAlert("Only author can add NFTs");

      if(!size) return Utils.setToastAlert("Collection upgrade size should be atleast 1");

      if(isProcess) return Utils.setToastAlert("Previous request processing","info");
      this.setState({preview:true})

    } catch (e) {
      Utils.setToastAlert("Unexpected error occured")
    }
  }

  render() {
    let {size,preview,isProcess} = this.state;
    return(
        <PopUp.PopupContainer
        onClose={()=>this.props.handleState({selected:"manual"})}
        title="Add New NFTs"
        >

      <div className="update__details__form">
        <div className="row no-gutters">


        <SignGroup >
          <label className="update__details__label" htmlFor="size">No. of NFTs to be added in collection</label>
          <input
            id="size"
            type="number"
            name="size"
            className="update__details__input"
            value={size}
            onChange={this.handleInput}
            readOnly={!!preview}
            />
        </SignGroup>
        </div>

        <div className="btn__panel">
          {!preview && <BackBtn largeView onClick={()=>this.props.handleState({selected:"manual"})}>Back</BackBtn>}
          {!preview && <CreateBtn largeView onClick={this.handlePreview}>Continue</CreateBtn>}
          {!!preview && !isProcess && <BackBtn largeView onClick={()=>this.setState({preview:false})} disabled={!!isProcess}>Go Back</BackBtn>}
          {!!preview && <CreateBtn fullView={isProcess} largeView={!isProcess} onClick={this.handleGenerateCollection}>
          Continue
          {!!isProcess && <div className="ball__pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>}
        </CreateBtn>}
        </div>
      </div>
        </PopUp.PopupContainer>
    )
  }
}




class PublishCollection extends React.Component {
  constructor(props){
  super(props)

    this.state = {
    isProcess:false,
    }
  }
  componentDidMount (){

  }

  handlePublic = async() =>{
      try {
        let {isProcess} = this.state;
        let{filled,collection} = this.props.data;
        let {author,collectionId,collectionType,isPublic,size} = collection;
        let address = Utils.viewCreator();

        if(!address) return $(".header__action-btn--signin").click();
        if(!collectionId) return Utils.setToastAlert("Collection does not exists");

        if(!address || author !== address) return Utils.setToastAlert("Only author can add NFTs");

        if(!!isPublic) return Utils.setToastAlert("Collection is already public");
        if(isProcess) return Utils.setToastAlert("Previous request processing","info");

        if(!filled) return Utils.setToastAlert("No NFT found in collection","info");
        if(collectionType === 1 && size > filled) return Utils.setToastAlert("Blind mintable collection's NFT should be filled completely before publishing","info");

        this.setState({isProcess:true})
        await api.publicIkCollection({address,collectionId});
        this.setState({isProcess:false})

        Utils.setToastAlert("Collection visibility updated successfully","success")
        this.props.handleReload()
      } catch (e) {
        // console.log(e);
        this.setState({isProcess:false})
        let msg = e.response?.data?.message??"Failed to update collection visibility";
        Utils.setToastAlert(msg,"error");
      }
  }

  render() {
    let {isProcess} = this.state;
    let{filled,collection,nftDoc} = this.props.data;
    // console.log(this.props.data.collection);
    let {collectionId,collectionType,isPublic,size,startAt,price,currencyId} = collection;

    let _startAt = !startAt?"-":startAt* 1000 > Date.now()? Utils.getTimeOffset(startAt,true):"Now";
    return(
      <PopUp.PopupContainer
      onClose={()=>this.props.handleState({selected:"manual"})}
      title="Collection Details"
      >
      <div className="ik_collection__popup__content">

        <div className="ikcollection__details">
          <div className="ikcollection__details_each">
            <p>Collection Id </p>:<span>{collectionId?collectionId:"-"}</span>
          </div>
          <div className="ikcollection__details_each">
            <p>Collection Size</p>:<span>{Utils.preety.unsafe(filled,0)}/{Utils.preety.unsafe(size,0)} NFTs</span>
          </div>
          <div className="ikcollection__details_each">
            <p>Minting Time</p>:<span>{_startAt}</span>
          </div>
          <div className="ikcollection__details_each">
            <p>Collection Type</p>:<span>{collectionType === 1?"Blind Mintable":collectionType === 2?"Fixed Price":collectionType === 3?"Upgradable":"-"}</span>
          </div>
          <div className="ikcollection__details_each">
            <p>Minting Price</p>:<span>{Utils.preety.unsafe(price/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
          </div>
          <div className="ikcollection__details_each">
            <p>NFT Contract</p>:<span>{nftDoc.name}</span>
          </div>
          <div className="ikcollection__details_each">
            <p>Visibility</p>:<span>{isPublic?"Public":"Private"}</span>
          </div>

        </div>

        <div className="btn__panel">
          <CreateBtn fullView onClick={this.handlePublic}>
            Public
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </CreateBtn>
        </div>

      </div>
      </PopUp.PopupContainer>
    )
  }
}





class BuyNft extends React.Component {
  constructor(props){
    super(props)
    this.state = {

      isProcess:false,
    }
  }

  handleBuy = async (event) => {

      try {
        let {isProcess} = this.state
        let {nft} = this?.props?.data??{}
        let {currencyId,price,collectionId,startAt,mintedSize,size} = this.props.data.collection;
        let tokenId = nft?.tokenId??0;
        // currencyId = "TTroZqb95vmsw4kppupQ8tVEzkNDDP2bcG"
        // currencyId = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(!tokenId) return Utils.setToastAlert("Non existant NFT selected");
        if(mintedSize === size) return Utils.setToastAlert("Collection already sold out");
        if(startAt >= (Date.now()/1000)) return Utils.setToastAlert("Minting not started yet");

        let callValue = price = Utils.ceil(price*1.05,0);

        if(isProcess) return Utils.setToastAlert("Previous request is in process");
        this.setState({isProcess:true})

        await new Promise(async(resolve,reject)=>{
          try {
            if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
              let _bal = await window.tronWeb.trx.getUnconfirmedBalance(address);
              _bal = Utils.floor(_bal);
              if(_bal < price) return reject('Not sufficient account balance');
              resolve()
            }else {
              callValue = 0;
              let tokenInstance = await window.tronWeb.contract().at(currencyId);
              let _bal = await tokenInstance.balanceOf(address).call();
              _bal = Utils.floor(window.tronWeb.toDecimal(_bal._hex));
              if(_bal < price) return reject('Not sufficient account balance');

              let allowed = await tokenInstance.allowance(address,Utils.KnftCollection).call();
              allowed = allowed.remaining?allowed.remaining:allowed;
              if(window.tronWeb.toDecimal(allowed._hex) > price){
                return resolve()
              }else {
                 let _allowing =  await tokenInstance.approve(Utils.KnftCollection, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
                  feeLimit:50 * 1e6,
                });
                resolve(_allowing)
              }
            }
          } catch (e) {
            // console.log(e);
            reject(e)
          }
        })

        let options = {feeLimit:300 * 1e6,callValue}
        let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
        await instance.mintCollection(address,tokenId,collectionId).send(options)

        Utils.setToastAlert("Transaction submitted successfully","success");
        this.setState({isProcess:false})
        this.props.refresh()
      } catch (e) {
        // console.log(e);
        this.setState({isProcess:false})
        let msg = e || "Unexpected error occured while buying NFT"
        Utils.setToastAlert(msg,"error")


      }
  }


  render() {
    let {isProcess} = this.state;
    let{collection,nft,nftDoc} = this?.props?.data??{};
    let {nftAddress,price,currencyId} = collection || {};
    let {tokenId} = nft || {};
    return(
        <PopUp.PopupContainer
        className="midsize__popup"
        onClose={()=>this.props.handleState({selected:""})}
        title="NFT Details"
        >


      <div className="ik_collection__popup__content">
        <div className="row no-gutters">
          <div className="col-12 col-md-6">
            <div className="card__cover">
              <NftViewer data={nft} key={tokenId}/>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="ikcollection__details">
              <div className="ikcollection__details_each">
                <p>Name</p>:<span>{nft.name || "Buy Now"}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Price</p>:<span>
                  {Utils.preety.unsafe(price/1e6,2)} + {Utils.preety.unsafe(price*0.05/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Quantity</p>:<span>1</span>
              </div>
              <div className="ikcollection__details_each">
                <p>NFT</p>:<span>{!!nftDoc?nftDoc.name:Utils.shortAddress(nftAddress)}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Token Id</p>:<span>{Utils.preety.unsafe(tokenId,0)}</span>
              </div>

            </div>
          </div>
        </div>


          <div className="btn__panel">
            {!isProcess && <BackBtn smlargeView onClick={()=>this.props.handleState({selected:""})} >Cancel</BackBtn>}
            <CreateBtn smfullView={isProcess} smlargeView={!isProcess} onClick={this.handleBuy}>
            Buy now
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </CreateBtn>

          </div>
      </div>
        </PopUp.PopupContainer>
    )
  }
}



class OfferNft extends React.Component {
  constructor(props){
    super(props)
    this.state = {

      amount:25,
      isProcess:false,
    }
  }
  componentDidMount(){
    let {bid,price} = this.props.data.collection;
    let amount = !!bid?bid:price?price*0.8:0;
    amount = Utils.ceil(amount/1e6,2);
    this.setState({amount})
  }

  handleInput = e =>{
  let input = Utils.inputNumber(e.target.value,2);
  this.setState({amount:input})
  }

  handleOffer = async (event) => {

      try {
        let{isProcess,amount} = this.state
        let {currencyId,price,collectionId,mintedSize,size,startAt} = this.props.data.collection;
        let {tokenId,bid} = this.props?.data?.nft??{tokenId:0,bidder:"",bid:0};

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(!tokenId) return Utils.setToastAlert("Non existant NFT selected");

        if(!amount) return Utils.setToastAlert('Bid amount can not be zero');
        if(amount < Utils.minBid(currencyId)) return Utils.setToastAlert(`Minimum bid limit ${ Utils.minBid(currencyId)} ${Utils.getCurrencyName(currencyId)}`);
        if(mintedSize === size) return Utils.setToastAlert("Collection already sold out");
        if(startAt >= (Date.now()/1000)) return Utils.setToastAlert("Minting not started yet");

        let callValue = amount = Utils.ceil(amount*1e6*1.05,0);

        if(bid >= amount) return Utils.setToastAlert("Higher offer already available");

        if(amount >= Utils.ceil(price*1.05,0)){
          Utils.setToastAlert("NFT can be bought directly");
          return this.props.handleState({selected:"buy"})
        }

        if(isProcess) return Utils.setToastAlert("Previous request is in process");
        this.setState({isProcess:true})

        await new Promise(async(resolve,reject)=>{
          try {
            if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
              let _bal = await window.tronWeb.trx.getUnconfirmedBalance(address);
              _bal = Utils.floor(_bal);
              if(_bal < amount) return reject('Not sufficient account balance');
              resolve()
            }else {
              callValue = 0;
              let tokenInstance = await window.tronWeb.contract().at(currencyId);
              let _bal = await tokenInstance.balanceOf(address).call();
              _bal = Utils.floor(window.tronWeb.toDecimal(_bal._hex));
              if(_bal < amount) return reject('Not sufficient account balance');

              let allowed = await tokenInstance.allowance(address,Utils.KnftCollection).call();
              allowed = allowed.remaining?allowed.remaining:allowed;
              if(window.tronWeb.toDecimal(allowed._hex) > amount){
                return resolve()
              }else {
                 let _allowing =  await tokenInstance.approve(Utils.KnftCollection, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
                  feeLimit:50 * 1e6,
                });
                resolve(_allowing)
              }
            }
          } catch (e) {
            // console.log(e);
            reject(e)
          }
        })

        let options = {feeLimit:300 * 1e6,callValue}
        let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
        await instance.makeOffer(collectionId,tokenId,amount).send(options)

        Utils.setToastAlert("Transaction submitted successfully","success");
        this.setState({isProcess:false})
        this.props.refresh()
      } catch (e) {
        console.log(e);
        this.setState({isProcess:false})
        let msg = e || "Unexpected error occured while offering NFT"
        Utils.setToastAlert(msg,"error")

      }
  }


  render() {
    let {isProcess,amount} = this.state;
    let{collection,nft,nftDoc} = this?.props?.data??{};
    let {nftAddress,price,currencyId} = collection || {};
    let {tokenId,bid} = nft || {};
    return(
        <PopUp.PopupContainer
        className="midsize__popup"
        onClose={()=>this.props.handleState({selected:""})}
        title="NFT Detail"
        >


      <div className="ik_collection__popup__content">
        <div className="row no-gutters">
          <div className="col-12 col-md-6">
            <div className="card__cover">
              <NftViewer data={nft} key={tokenId}/>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="ikcollection__details">
              <div className="ikcollection__details_each">
                <p>Name</p>:<span>{nft.name || "Buy Now"}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Price</p>:<span>
                  {Utils.preety.unsafe(price/1e6,2)} + {Utils.preety.unsafe(price*0.05/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>NFT</p>:<span>{!!nftDoc?nftDoc.name:Utils.shortAddress(nftAddress)}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Token Id</p>:<span>{Utils.preety.unsafe(tokenId,0)}</span>
              </div>
              <div className="ikcollection__details_each">
                <p>Your Offer</p>:
                <input
                  type="number"
                  className="sign__input"
                  autoFocus
                  value={amount}
                  onChange={this.handleInput}
                  readOnly={!!isProcess}
                  />
              </div>

            </div>

          </div>

        </div>




          <div className="btn__panel">
            {!isProcess && <BackBtn smlargeView onClick={()=>this.props.handleState({selected:""})} >Cancel</BackBtn>}
            <CreateBtn fullView={isProcess} smlargeView={!isProcess} onClick={this.handleOffer}>
            Offer
            {!!isProcess && <div className="ball__pulse">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </CreateBtn>

          </div>
          <p className="alert__msg">
             ** NOTE, &nbsp;
            {!bid && <span>This offer can be cancelled by you anytime.</span>}
            {!!bid && <span>Offer will be locked for 24 hours.</span>}

          </p>
      </div>
        </PopUp.PopupContainer>
    )
  }
}

class WithdrawOfferNft extends React.Component {
  constructor(props){
    super(props)
    this.state = {

      isProcess:false,
    }
  }

  handleWithdrawOffer = async (event) => {

      try {
        let{isProcess} = this.state
        let {collectionId} = this.props.data.collection;
        let {tokenId,bidder,bid,bidTime} = this.props?.data?.nft??{tokenId:0,bidder:"",bid:0,bidTime:0};

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(!tokenId) return Utils.setToastAlert("Non existant NFT selected");
        if(!bid) return Utils.setToastAlert("Offer not available");
        if(bidder !== address) return Utils.setToastAlert("Only bidder can withdraw offer");
        if(bidTime > Date.now()) return Utils.setToastAlert(`Bid can be removed after ${Utils.getRemainigTime(bidTime)}`);

        if(isProcess) return Utils.setToastAlert("Previous request is in process");
        this.setState({isProcess:true})

        let options = {feeLimit:300 * 1e6}
        let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
        await instance.withdrawOffer(collectionId,tokenId).send(options)

        Utils.setToastAlert("Transaction submitted successfully","success");
        this.setState({isProcess:false})
        this.props.refresh()
      } catch (e) {
        // console.log(e);
        this.setState({isProcess:false})
        let msg = e || "Unexpected error occured while withdrawing offer"
        Utils.setToastAlert(msg,"error")

      }
  }


  render() {
    let {isProcess} = this.state;
    let{collection,nft,nftDoc} = this?.props?.data??{};
    let {nftAddress,price,currencyId} = collection || {};
    let {tokenId,bid} = nft || {};


    return(
      <PopUp.PopupContainer
        className="midsize__popup"
        onClose={()=>this.props.handleState({selected:""})}
        title="NFT Detail">

        <div className="ik_collection__popup__content">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <div className="card__cover">
                <NftViewer data={nft} key={tokenId}/>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="ikcollection__details">
                <div className="ikcollection__details_each">
                  <p>Name</p>:<span>{nft.name || "Buy Now"}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Price</p>:<span>
                    {Utils.preety.unsafe(price/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>NFT</p>:<span>{!!nftDoc?nftDoc.name:Utils.shortAddress(nftAddress)} </span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Token Id</p>:<span>{Utils.preety.unsafe(tokenId,0)}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Offered</p>:<span>{Utils.preety.unsafe(bid/1.05/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
                </div>
              </div>
            </div>
          </div>

            <div className="btn__panel">
              {!isProcess && <BackBtn smlargeView onClick={()=>this.props.handleState({selected:""})} >Cancel</BackBtn>}
              <CreateBtn fullView={isProcess} smlargeView={!isProcess} onClick={this.handleWithdrawOffer}>
              Withdraw
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </CreateBtn>
            </div>
        </div>
          </PopUp.PopupContainer>
    )
  }
}


class AccceptOfferNft extends React.Component {
  constructor(props){
    super(props)
    this.state = {

      isProcess:false,
    }
  }

  handleWithdrawOffer = async (event) => {

      try {
        let{isProcess} = this.state
        let {collectionId,author} = this.props.data.collection;
        let {tokenId,bid} = this.props?.data?.nft??{tokenId:0,bidder:"",bid:0};

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(!tokenId) return Utils.setToastAlert("Non existant NFT selected");
        if(!bid) return Utils.setToastAlert("Offer not available");
        if(address !== author) return Utils.setToastAlert("Only author can accept offer");

        if(isProcess) return Utils.setToastAlert("Previous request is in process");
        this.setState({isProcess:true})

        let options = {feeLimit:300 * 1e6}
        let instance = await window.tronWeb.contract().at(Utils.KnftCollection);
        await instance.acceptOffer(collectionId,tokenId).send(options)

        Utils.setToastAlert("Transaction submitted successfully","success");
        this.setState({isProcess:false})
        this.props.refresh()
      } catch (e) {
        console.log(e);
        this.setState({isProcess:false})
        let msg = e || "Unexpected error occured while accepting offer"
        Utils.setToastAlert(msg,"error")

      }
  }


  render() {
    let {isProcess} = this.state;
    let{collection,nft,nftDoc} = this?.props?.data??{};
    let {nftAddress,price,currencyId} = collection || {};
    let {tokenId,bid} = nft || {tokenId:0,bidder:"",bid:0};

    return(
      <PopUp.PopupContainer
          className="midsize__popup"
          onClose={()=>this.props.handleState({selected:""})}
          title="NFT Detail">



          <h4 className="sign__title">NFT Details</h4>



        <div className="ik_collection__popup__content">
          <div className="row no-gutters">
            <div className="col-12 col-md-6">
              <div className="card__cover">
                <NftViewer data={nft} key={tokenId}/>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="ikcollection__details">
                <div className="ikcollection__details_each">
                  <p>Name</p>:<span>{nft.name || "Buy Now"}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Price</p>:<span>
                    {Utils.preety.unsafe(price/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>NFT</p>:<span>{!!nftDoc?nftDoc.name:Utils.shortAddress(nftAddress)}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Token Id</p>:<span>{Utils.preety.unsafe(tokenId,0)}</span>
                </div>
                <div className="ikcollection__details_each">
                  <p>Offered</p>:<span>{Utils.preety.unsafe(bid/1.05/1e6,2)} {Utils.getCurrencyName(currencyId)}</span>
                </div>
              </div>
            </div>
          </div>

            <div className="btn__panel">
              {!isProcess && <BackBtn smlargeView onClick={()=>this.props.handleState({selected:""})} >Cancel</BackBtn>}
              <CreateBtn fullView={isProcess} smlargeView={!isProcess} onClick={this.handleWithdrawOffer}>
              Accept
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </CreateBtn>
            </div>
        </div>
          </PopUp.PopupContainer>
    )
  }
}




class BlindMintIk extends React.Component {
  constructor(props){
    super(props)
    this.state = {

      mintSize:1,
      isProcess:false,
    }
  }

  componentDidMount(){

  }

  handleBlindMint = async (event) => {

      try {
        let{isProcess,mintSize} = this.state
        let {currencyId,price,collectionId,author,mintedSize,size,startAt} = this.props.data.collection;

        let address = Utils.viewCreator();
        if(!address) return $(".header__action-btn--signin").click();
        if(mintedSize === size) return Utils.setToastAlert("Collection already sold out");
        if(startAt >= (Date.now()/1000)) return Utils.setToastAlert("Minting not started yet");

        let callValue = price = Utils.ceil(price*mintSize*1.05,0);

        if(isProcess) return Utils.setToastAlert("Previous request is in process");
        this.setState({isProcess:true})

        let mintContract = collectionId >= 100?Utils.KnftCollection:Utils.BlindContract;


        await new Promise(async(resolve,reject)=>{
          try {
            if(currencyId === "T9yD14Nj9j7xAB4dbGeiX9h8unkKHxuWwb"){
              let _bal = await window.tronWeb.trx.getUnconfirmedBalance(address);
              _bal = Utils.floor(_bal);
              if(_bal < price) return reject('Not sufficient account balance');
              resolve()
            }else {
              callValue = 0;
              let tokenInstance = await window.tronWeb.contract().at(currencyId);
              let _bal = await tokenInstance.balanceOf(address).call();
              _bal = Utils.floor(window.tronWeb.toDecimal(_bal._hex));
              if(_bal < price) return reject('Not sufficient account balance');

              let allowed = await tokenInstance.allowance(address,mintContract).call();
              allowed = allowed.remaining?allowed.remaining:allowed;
              if(window.tronWeb.toDecimal(allowed._hex) > price){
                return resolve()
              }else {
                 let _allowing =  await tokenInstance.approve(mintContract, "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff").send({
                  feeLimit:50 * 1e6,
                });
                resolve(_allowing)
              }
            }
          } catch (e) {
            // console.log(e);
            reject(e)
          }
        })


        let options = {feeLimit:300 * 1e6,callValue}

        await new Promise(async(resolve,reject)=>{
          try {
            if(collectionId >= 100){
              let instance = await window.tronWeb.contract().at(Utils.KnftCollection);

              if(mintSize ===1 ){
                await instance.blindMint(address,collectionId).send(options)
                resolve()
              }else {
                await instance.blindMultiMint(address,collectionId,mintSize).send(options)
                resolve()
              }

            }else {

              if(mintSize ===1 ){
                let instance = await window.tronWeb.contract().at(Utils.BlindContract);
                await instance.mintBlindByTrx(address,author).send(options);
                resolve()
              }else {
                options.feeLimit = 150 * 1e6 * mintSize;
                let instance = await window.tronWeb.contract().at(Utils.BlindProxy);
                await instance.mintBlindByTrx(author,mintSize).send(options);
                resolve()
              }

            }
          } catch (e) {
            reject(e)
          }
        })


        Utils.setToastAlert("Transaction submitted successfully","success");
        this.setState({isProcess:false})
        this.props.refresh()
      } catch (e) {
        console.log(e);
        this.setState({isProcess:false})
        let msg = e || "Unexpected error occured while blind minting"
        Utils.setToastAlert(msg,"error")

      }
  }


  render() {
    let {isProcess,mintSize} = this.state;


    let{collection} = this.props.data;
    let {price,currencyId,mintedSize,size} = collection;
    let mintingOption =  size === mintedSize?1:size - mintedSize >= 10?10:size - mintedSize;


    return(
      <PopUp.PopupContainer
          onClose={()=>this.props.handleState({selected:""})}
          title="Blind Mint"
          >

          <div className="update__details__form">
            <div className="row no-gutters">


            <SignGroup >
              <label className="update__details__label" htmlFor="mint_size">No. of NFT</label>
                <select
                  id="mint_size"
                  name="mint_size"
                  className="update__details__select"
                  onChange={(e)=>this.setState({mintSize:e.target.value})}
                  value={mintSize}
                  readOnly={!!isProcess}
                  >
                  {new Array(mintingOption).fill(undefined).map((i,j)=>{
                    return <option value={j+1} key={j+1}>{j+1}</option>
                  })}
                </select>
                <p className="sign__group__right__label">
                  Total :- {Utils.preety.unsafe(price*mintSize/1e6,2)} + {Utils.preety.unsafe(price*mintSize*0.05/1e6,2)} {Utils.getCurrencyName(currencyId)}
                </p>
            </SignGroup>
            </div>

            <div className="btn__panel">
              {!isProcess && <BackBtn largeView onClick={()=>this.props.handleState({selected:""})} >Cancel</BackBtn>}
              <CreateBtn fullView={isProcess} largeView={!isProcess} onClick={this.handleBlindMint}>
              Mint
              {!!isProcess && <div className="ball__pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>}
            </CreateBtn>
            </div>
            <p className="alert__msg">
               ** NOTE, &nbsp; <span>This is a blind mintable collection, you will get any random NFT from the collection.</span>
            </p>
          </div>


          </PopUp.PopupContainer>
    )
  }
}

export {
  CollectionMain,
  CollectionFillSelect,
  JsonMethod,
  GenerateMethod,
  AttachMultiFile,
  UpgradeCollection,
  PublishCollection,



  BuyNft,OfferNft,WithdrawOfferNft,AccceptOfferNft,BlindMintIk,
}
