
import React,{ useEffect,Suspense, lazy } from 'react'
import {Routes, Route,BrowserRouter,useLocation} from 'react-router-dom';

import { Header,Footer } from '../components'
import * as pages from '../pages'
import * as helper from '../helper'

import '../scss/main.scss';

import LazyLoader  from "../reuseable/loader/lazyLoader"

const MinatbleCollections = lazy(() => import('../mintable-collection/app.js'));

// const Trocks = lazy(() =>
//   new Promise((resolve) => {
//     setTimeout(() => resolve(import("../sub-project/trocks")), 3000); // 3-second delay
//   })
// );
// const CryptoClouds = lazy(() =>
//   new Promise((resolve) => {
//     setTimeout(() => resolve(import("../sub-project/cypto-clouds")), 3000); // 3-second delay
//   })
// );
const Trocks = lazy(() => import('../sub-project/trocks'));
const CryptoClouds = lazy(() => import('../sub-project/cypto-clouds'));
const Fox555 = lazy(() => import('../sub-project/fox555/app.js'));
const TechMemory = lazy(() => import('../sub-project/tech-memory/app.js'));
//
// const WebGL3d = lazy(() => import('../sub-project/webGL3d/app.js'));
// const Admin = lazy(() => import('../admin/app.js'));


const LazyTrocks = () => (
  <Suspense fallback={<LazyLoader/>}>
    <Trocks/>
  </Suspense>
);
const LazyCryptoClouds = () => (
  <Suspense fallback={<LazyLoader/>}>
    <CryptoClouds/>
  </Suspense>
);
const LazyFox555 = () => (
  <Suspense fallback={<LazyLoader/>}>
    <Fox555/>
  </Suspense>
);
const LazyTechMemory = () => (
  <Suspense fallback={<LazyLoader/>}>
    <TechMemory/>
  </Suspense>
);
const LazyMinatbleCollections = () => (
  <Suspense fallback={<LazyLoader/>}>
    <MinatbleCollections/>
  </Suspense>
);

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Suspense fallback={null}>
        <Routes>
          <Route index element={<pages.Home/>}/>
          <Route path="/home" element={<pages.Home/>} />

          <Route path="/explore" element={<pages.Explore/>} />
          <Route path="/explore/:collection" element={<pages.Explore3p/>} />

          <Route path="/discover" element={<pages.Discover/>} />

          <Route path="/author/:address" element={<pages.Author/>} />
          <Route path="/author/:address/profile" element={<pages.AuthorProfile/>} />
          <Route path="/author/:address/rewards" element={<pages.Rewards/>} />
          <Route path="/author/:address/referral" element={<pages.Referral/>} />

          <Route path="/authors" element={<pages.Authors/>} />

          <Route path="/create" element={<pages.Create/>} />

          <Route path="/market/:tokenId" element={<pages.NftDetails/>} />
          <Route path="/market3p/:nftAddress/:tokenId" element={<pages.Market3p/>} />

          <Route path="/search/:query" element={<pages.SearchPanel/>} />

          <Route path="/canvas" element={<pages.CanvasArt/>} />

          {/* <Route path="/kty-offering" element={<pages.GenesisOffering/>} /> */}
          <Route path="/kty" element={<pages.KTY/>} />
          <Route path="/kty-info" element={<pages.KTY/>} />
          <Route path="/kty-burn" element={<pages.KTYBurnPool/>} />

          <Route path="/article" element={<helper.ComingSoon/>} />
          <Route path="/help" element={<helper.ComingSoon/>} />
          <Route path="/feedback" element={<helper.ComingSoon/>} />
          <Route path="/collection" element={<helper.ComingSoon/>} />
          <Route path="/privacy" element={<helper.ComingSoon/>} />


          <Route path="/trocks" element={<LazyTrocks/>} />
          <Route path="/cypto-clouds" element={<LazyCryptoClouds/>} />
          <Route path="/fox555/*" element={<LazyFox555/>} />
          <Route path="/tech-memory/*" element={<LazyTechMemory/>} />

          <Route path="/mintable/*" element={<LazyMinatbleCollections/>} />

          {/* <Route path="/3d" element={WebGL3d} /> */}
          {/* <Route path="/admin" element={Admin} /> */}

          <Route path="*" element={<helper.PageNotFound/>} />

        </Routes>
      </Suspense>
      <Footer />
    </BrowserRouter>
  )
}

export default App
